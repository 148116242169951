import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import btoa from 'btoa'
import checkNested from 'check-nested'
import { defaultState, defaultStateRouter } from './defaultState'

export default {
  namespaced: true,
  state: { ...defaultState(), ...defaultStateRouter() },
  getters: {
    mqShallShowLeftSidebar: state => state.mqShallShowLeftSidebar.value,
    tabContentStepShow: state => state.tabContentStepShow.value,
    form_data: state => state.form_data.value,
    form_sw086_default: state => state.form_sw086_default.value,
    form_sw078_default: state => state.form_sw078_default.value,
    form_sw086: state => state.form_sw086.value,
    form_sw078: state => state.form_sw078.value,
    keyReg: state => state.keyReg.value,
    dynamicIDVersion: state => state.dynamicIDVersion.value,
    idVersionReg: state => state.idVersionReg.value,
    dynamicIDForm: state => state.dynamicIDForm.value,
    modeFormAction: state => state.modeFormAction.value,
    modeFormDraft: state => state.modeFormAction.value,
    loaded: state => state.loaded.value,
    luxury: state => state.luxury.value,
    crypto: state => state.crypto.value,
    idAgent: state => state.idAgent.value,
    idAgentLead: state => state.idAgentLead.value,
    lead: state => state.lead.value,
    idLeadAssoc: state => state.idLeadAssoc.value,
    idBankAssoc: state => state.idBankAssoc.value,
    empreAssoc: state => state.empreAssoc.value,
    idUserSearch: state => state.idUserSearch.value,
    oldCE: state => state.oldCE.value,
    previewImage: state => state.previewImage.value,
    load_data_geral: state => ({
      infoAgent: state.infoAgent.value,
      infoMarket: state.infoMarket.value,
      usersAgent: state.usersAgent.value,
      especialistas: state.especialistas.value,
      leader: state.leader.value,
      infoLead: state.infoLead.value,
      infoBank: state.infoBank.value,
      infoEmpreendimento: state.infoEmpreendimento.value,
      languages: state.languages.value,
      banks: state.banks.value,
      energy: state.energy.value,
      typologiesClasses: state.typologiesClasses.value,
      conditions: state.conditions.value,
      districts: state.districts.value,
      enterprises: state.enterprises.value,
      listingStates: state.listingStates.value,
      finality: state.finality.value,
      floors: state.floors.value,
      solarOrientations: state.solarOrientations.value,
      descriptonScripts: state.descriptonScripts.value,
      typeBusiness: state.typeBusiness.value,
      typeLands: state.typeLands.value,
      typologies: state.typologies.value,
      typeCategory: state.typeCategory.value,
      typeRegime: state.typeRegime.value,
      attributes: state.attributes.value,
      garages: state.garages.value,
      places: state.places.value,
      typeListings: state.typeListings.value,
      subTypeListings: state.subTypeListings.value,
      typologiesExtra: state.typologiesExtra.value,
      descriptonTrads: state.descriptonTrads.value,
      rentPeriod: state.rentPeriod.value,
      arrayYesNo: state.arrayYesNo.value,
      array20: state.array20.value,
      array70: state.array70.value,
      arrayMeses: state.arrayMeses.value,
      arrayMesesOutro: state.arrayMesesOutro.value,
      arrayPermuta: state.arrayPermuta.value,
      arrayCondominioYesNo: state.arrayCondominioYesNo.value,
      arrayCondominio: state.arrayCondominio.value,
      arraySuites: state.arraySuites.value,
      typeGarage: state.typeGarage.value,
      blockBankOption: state.blockBankOption.value,
      historyPricesListing: state.historyPricesListing.value,
      formPreAngariacao: state.formPreAngariacao.value,
      currentState: state.currentState.value,
      forceWebsiteConfig: state.forceWebsiteConfig.value,
      showVersionPending: state.showVersionPending.value,
      idVersionRegViewer: state.idVersionRegViewer.value,
      diasVendido: state.diasVendido.value,
      urlWebsite: state.urlWebsite.value,
      urlWebsiteLuxury: state.urlWebsiteLuxury.value,
      urlWebsiteImovirtual: state.urlWebsiteImovirtual.value,
      urlWebsiteListglobally: state.urlWebsiteListglobally.value,
      urlWebsiteCryptos: state.urlWebsiteCryptos.value,
      urlWebsiteIdealista: state.urlWebsiteIdealista.value,
      urlWebsiteCasayes: state.urlWebsiteCasayes.value,
      bLuxurySelectable: state.bLuxurySelectable.value,
      bCryptoSelectable: state.bCryptoSelectable.value,
      showVersionReg: state.showVersionReg.value,
      aprovaListing: state.aprovaListing.value,
      temEstudoMercado: state.temEstudoMercado.value,
      userChangeCanEditAng: state.userChangeCanEditAng.value,
      editListingHM: state.userChangeCanEditAng.value,
      stateImovirtual: state.stateImovirtual.value,
      imovirtualErrorMessage: state.imovirtualErrorMessage.value,
      imovirtualModerateMessage: state.imovirtualModerateMessage.value,
      idealistaError: state.idealistaError.value,
      casayesError: state.casayesError.value,
      limitContratoMediacao: state.limitContratoMediacao.value,
      arrayConstruction: state.arrayConstruction.value,
      planoComunicacao: state.planoComunicacao.value,
      subTiposImoveis: state.subTiposImoveis.value,
      subTiposImoveisSel: state.subTiposImoveisSel.value,
      idsTypeListingVenture: state.idsTypeListingVenture.value,
      checkStatusApproveVersionReg: state.checkStatusApproveVersionReg.value,
      totalImages: state.totalImages.value,
      certificadoBy: state.certificadoBy.value,
    }),
    formModeView: state => ((state.modeFormAction.value === 1)),
    appConfig: state => state.appConfig.value,
    vars: state => ({
      previewPhotos: state.previewPhotos.value,
      sw012: state.form_data_sw012,
      linkInterno: state.linkInterno,
    }),
    loadTabMedia: state => state.loadTabMedia.value,
    loadTabPrivado: state => state.loadTabPrivado.value,
    loadTabAtividade: state => state.loadTabAtividade.value,
    loadTabPlano: state => state.loadTabPlano.value,
    filterAttributes: state => ({
      essenciais: state.essenciais.value,
      areas: state.areas.value,
      extras: state.extras.value,
      vistas: state.vistas.value,
      energia: state.energia.value,
      seguranca: state.seguranca.value,
      espacos: state.espacos.value,
      exterior: state.exterior.value,
      localizacao: state.localizacao.value,
      showExtras: state.showExtras.value,
      showVistas: state.showVistas.value,
      showEnergia: state.showEnergia.value,
      showSeguranca: state.showSeguranca.value,
      showEspacos: state.showEspacos.value,
      showExterior: state.showExterior.value,
      showLocalizacao: state.showLocalizacao.value,
    }),
    showAttributes: state => ({
      showAttrAssoalhadas: state.showAttrAssoalhadas.value,
      showAttrSuite: state.showAttrSuite.value,
      showAttrRoom: state.showAttrRoom.value,
      showAttrLugaresGaragem: state.showAttrLugaresGaragem.value,
      showAttrQuintal: state.showAttrQuintal.value,
      showAttrCozinha: state.showAttrCozinha.value,
      showAttrSala: state.showAttrSala.value,
      showAttrPiscina: state.showAttrPiscina.value,
    }),
    filterFields: state => ({
      currentlyRent: state.currentlyRent.value,
    }),
    rulesFields: state => state.rulesFields.value,
    rulesFieldsAngariacao: state => state.rulesFieldsAngariacao.value,
    rulesFieldsPreAngariacao: state => state.rulesFieldsPreAngariacao.value,
    rulesAttrFields: state => state.rulesAttrFields.value,
    rulesAttrFieldsAngariacao: state => state.rulesAttrFieldsAngariacao.value,
    rulesAttrFieldsPreAngariacao: state => state.rulesAttrFieldsPreAngariacao.value,
    portals: state => state.portals.value,
    filterChangeAgent: state => state.filterChangeAgent.value,
    disableRegimeContratacao: state => state.disableRegimeContratacao.value,
    invalidFields: state => state.invalidFields.value,
  },
  mutations: {
    resetState(state) {
      const updateState = { ...defaultState() }
      const defaultStateRouterDefault = defaultStateRouter()

      if (Object.keys(defaultStateRouterDefault).length > 0) {
        Object.keys(defaultStateRouterDefault).forEach(index => {
          updateState[index] = state[index]
        })
      }

      Object.assign(state, updateState)
    },
    setParamsUrl(state, payload) {
      state.paramsUrl.value = payload
    },
    setTypeAction(state, payload) {
      state.typeAction.value = payload || ''
    },
    setModeViewDetail(state, payload) {
      state.modeFormAction.value = payload
    },
    setModeDraft(state, payload) {
      state.modeFormDraft.value = payload
    },
    setAppConfig(state, payload) {
      state.appConfig.value = payload
      if (typeof payload.aComissionPercentageShare !== 'undefined') {
        const comission = []
        Object.entries(payload.aComissionPercentageShare).forEach(element => {
          comission.push({ id: element[0], desc: element[1] })
        })
        state.appConfig.value.aComissionPercentageShare = comission
      }
    },
    setMqShallShowLeftSidebar(state, payload) {
      state.mqShallShowLeftSidebar.value = payload
    },
    setTabContentStepShow(state, payload) {
      state.tabContentStepShow.value = payload
    },
    setDynamicIDForm(state, payload) {
      state.dynamicIDForm.value = payload
    },
    setLuxury(state, payload) {
      state.luxury.value = payload
    },
    setCrypto(state, payload) {
      state.crypto.value = payload
    },
    setIdAgent(state, payload) {
      state.idAgent.value = payload
    },
    setIdAgentLead(state, payload) {
      state.idAgentLead.value = payload
    },
    setLoaded(state, payload) {
      state.loaded.value = payload
    },
    setInfoAgent(state, payload) {
      state.infoAgent.value = payload
    },
    setInfoMkt(state, payload) {
      state.infoMarket.value = payload
    },
    setUsersAgents(state, payload) {
      state.usersAgent.value = payload
    },
    setLanguages(state, payload) {
      state.languages.value = payload
    },
    setBanks(state, payload) {
      state.banks.value = payload
    },
    setEnergy(state, payload) {
      state.energy.value = payload
    },
    setTypologiesClasses(state, payload) {
      state.typologiesClasses.value = payload
    },
    setConditions(state, payload) {
      state.conditions.value = payload
    },
    setDistricts(state, payload) {
      state.districts.value = payload
    },
    setEnterprises(state, payload) {
      state.enterprises.value = payload
    },
    setListingsStates(state, payload) {
      state.listingStates.value = payload
    },
    setFinality(state, payload) {
      state.finality.value = payload
    },
    setFloors(state, payload) {
      state.floors.value = payload
    },
    setSolarOrientations(state, payload) {
      state.solarOrientations.value = payload
    },
    setDescriptionScripts(state, payload) {
      state.descriptonScripts.value = payload
    },
    setSW012(state, payload) {
      state.form_data.value = payload
    },
    setTypeBusiness(state, payload) {
      state.typeBusiness.value = payload
    },
    setTypeLand(state, payload) {
      state.typeLands.value = payload
    },
    setTypologies(state, payload) {
      state.typologies.value = payload
    },
    setTypeCategory(state, payload) {
      state.typeCategory.value = payload
    },
    setTypeListings(state, payload) {
      state.typeListings.value = payload
    },
    setSubTypeListings(state, payload) {
      state.subTypeListings.value = payload
    },
    setTypologiesExtra(state, payload) {
      state.typologiesExtra.value = payload
    },
    setListingTradDesc(state, payload) {
      state.descriptonTrads.value = payload
    },
    setRentPeriod(state, payload) {
      state.rentPeriod.value = payload
    },
    setPreviewPhotos(state, payload) {
      state.previewPhotos.value = payload
    },
    setPreviewImage(state, payload) {
      state.previewImage.value = payload
    },
    setLoadTabMedia(state, payload) {
      state.loadTabMedia.value = payload
    },
    setLoadTabPrivado(state, payload) {
      state.loadTabPrivado.value = payload
    },
    setLoadTabAtividade(state, payload) {
      state.loadTabAtividade.value = payload
    },
    loadTabPlano(state, payload) {
      state.loadTabPlano.value = payload
    },
    setSubTipoImoveis(state, payload) {
      state.subTiposImoveis.value = payload
    },
    setSubTipoImoveisSel(state, payload) {
      state.subTiposImoveisSel.value = payload
    },
    setAttributes(state, payload) {
      state.attributes.value = payload

      if (typeof state.attributes.value.energia !== 'undefined') {
        const energy = []
        Object.entries(state.attributes.value.energia).forEach(element => {
          energy.push({ id: element[0], desc: element[1] })
        })

        state.attributes.value.energia = energy
      }

      if (typeof state.attributes.value.espacos !== 'undefined') {
        const spaces = []
        Object.entries(state.attributes.value.espacos).forEach(element => {
          spaces.push({ id: element[0], desc: element[1] })
        })

        state.attributes.value.espacos = spaces
      }

      if (typeof state.attributes.value.essencial_sw012 !== 'undefined') {
        const essencial = []
        Object.entries(state.attributes.value.essencial_sw012).forEach(element => {
          essencial.push({ id: element[0], desc: element[1] })
        })

        state.attributes.value.essencial_sw012 = essencial
      }

      if (typeof state.attributes.value.exterior !== 'undefined') {
        const exterior = []
        Object.entries(state.attributes.value.exterior).forEach(element => {
          exterior.push({ id: element[0], desc: element[1] })
        })

        state.attributes.value.exterior = exterior
      }

      if (typeof state.attributes.value.extras !== 'undefined') {
        const extras = []
        Object.entries(state.attributes.value.extras).forEach(element => {
          extras.push({ id: element[0], desc: element[1] })
        })

        state.attributes.value.extras = extras
      }

      if (typeof state.attributes.value.seguranca !== 'undefined') {
        const seguranca = []
        Object.entries(state.attributes.value.seguranca).forEach(element => {
          seguranca.push({ id: element[0], desc: element[1] })
        })

        state.attributes.value.seguranca = seguranca
      }

      if (typeof state.attributes.value.vistas !== 'undefined') {
        const vistas = []
        Object.entries(state.attributes.value.vistas).forEach(element => {
          vistas.push({ id: element[0], desc: element[1] })
        })

        state.attributes.value.vistas = vistas
      }

      if (typeof state.attributes.value.localizacao !== 'undefined') {
        const localizacoes = []
        Object.entries(state.attributes.value.localizacao).forEach(element => {
          localizacoes.push({ id: element[0], desc: element[1] })
        })

        state.attributes.value.localizacao = localizacoes
      }
    },
    setMapLatLong(state, payload) {
      try {
        if (payload.lat && payload.lng) {
          state.form_data.value.sw012s33 = `${payload.lat}###${payload.lng}`
        } else {
          state.form_data.value.sw012s33 = ''
        }
      } catch (err) {
        state.form_data.value.sw012s33 = ''
      }
    },
    setUpdateFormData(state, payload) {
      state.form_data.value = { ...payload }
    },
    setUpdateFormDataField(state, payload) {
      try {
        if (payload.field && (payload.value || payload.value === null)) {
          state.form_data.value[payload.field] = payload.value
        }
      } catch (err) {
        //
      }
    },
    setUpdateRuleField(state, payload) {
      try {
        if (payload.field && (payload.value === true || payload.value === false)) {
          if (typeof state.rulesFields.value[payload.field] !== 'undefined') {
            state.rulesFields.value[payload.field].required = payload.value
          } else {
            state.rulesFields.value[payload.field] = { required: payload.value }
          }
        }
      } catch (err) {
        //
      }
    },
    setKeyReg(state, payload) {
      state.keyReg.value = payload
    },
    setDynamicIDVersion(state, payload) {
      state.dynamicIDVersion.value = payload
    },
    setIdVersionReg(state, payload) {
      state.idVersionReg.value = payload
    },
    setFormDataSW012(state, payload) {
      state.form_data_sw012 = payload || {}
    },
    setInitFiltersAttributes(state) {
      for (let index = 1; index < 21; index += 1) {
        state.places.value.push({ id: index, desc: index })
      }

      for (let index = 1; index < 21; index += 1) {
        state.array20.value.push({ id: index, desc: index })
      }

      for (let index = 1; index < 71; index += 1) {
        state.array70.value.push({ id: index, desc: index })
      }

      for (let index = 1; index < 71; index += 1) {
        state.arraySuites.value.push({ id: index, desc: index })
      }
    },
    addNewConservatoria(state) {
      state.form_sw086.value.push({
        sw086s05: state.form_sw086_default.value[0].sw086s05,
        sw086s06: state.form_sw086_default.value[0].sw086s06,
        sw086s07: state.form_sw086_default.value[0].sw086s07,
      })

      state.form_sw086_default.value[0].sw086s05 = ''
      state.form_sw086_default.value[0].sw086s06 = ''
      state.form_sw086_default.value[0].sw086s07 = ''
    },
    removeConservatoria(state, payload) {
      if (state.form_sw086.value !== undefined) {
        if (payload in state.form_sw086.value) {
          state.form_sw086.value.splice(payload, 1)
        }
      }
    },
    addNewCaderneta(state) {
      state.form_sw078.value.push({
        sw078s05: state.form_sw078_default.value[0].sw078s05,
        sw078s06: state.form_sw078_default.value[0].sw078s06,
        sw078s07: state.form_sw078_default.value[0].sw078s07,
      })

      state.form_sw078_default.value[0].sw078s05 = ''
      state.form_sw078_default.value[0].sw078s06 = ''
      state.form_sw078_default.value[0].sw078s07 = ''
    },
    removeCaderneta(state, payload) {
      if (state.form_sw078.value !== undefined) {
        if (payload in state.form_sw078.value) {
          state.form_sw078.value.splice(payload, 1)
        }
      }
    },
    setEssenciasAttributes(state, payload) {
      state.essenciais.value = payload
    },
    setAreasAttributes(state, payload) {
      state.areas.value = payload
    },
    setExtrasAttributes(state, payload) {
      state.extras.value = payload
    },
    setVistasAttributes(state, payload) {
      state.vistas.value = payload
    },
    setEnergiaAttributes(state, payload) {
      state.energia.value = payload
    },
    setSegurancaAttributes(state, payload) {
      state.seguranca.value = payload
    },
    setEspacosAttributes(state, payload) {
      state.espacos.value = payload
    },
    setExteriorAttributes(state, payload) {
      state.exterior.value = payload
    },
    setShowExtras(state, payload) {
      state.showExtras.value = payload
    },
    setShowVistas(state, payload) {
      state.showVistas.value = payload
    },
    setShowEnergia(state, payload) {
      state.showEnergia.value = payload
    },
    setShowSeguranca(state, payload) {
      state.showSeguranca.value = payload
    },
    setShowEspacos(state, payload) {
      state.showEspacos.value = payload
    },
    setShowExterior(state, payload) {
      state.showExterior.value = payload
    },
    setShowLocalizacao(state, payload) {
      state.showLocalizacao.value = payload
    },
    setShowAssoalhadas(state, payload) {
      state.showAttrAssoalhadas.value = payload
    },
    setShowSuite(state, payload) {
      state.showAttrSuite.value = payload
    },
    setShowRoom(state, payload) {
      state.showAttrRoom.value = payload
    },
    setShowLugaresGaragem(state, payload) {
      state.showAttrLugaresGaragem.value = payload
    },
    setShowQuintal(state, payload) {
      state.showAttrQuintal.value = payload
    },
    setShowCozinha(state, payload) {
      state.showAttrCozinha.value = payload
    },
    setShowSala(state, payload) {
      state.showAttrSala.value = payload
    },
    setShowPiscina(state, payload) {
      state.showAttrPiscina.value = payload
    },
    setArraySuites(state, payload) {
      state.arraySuites.value = []
      state.arraySuites.value.push({ id: 0, desc: i18n.t('Não') })
      for (let index = 1; index < (payload + 1); index += 1) {
        state.arraySuites.value.push({ id: index, desc: index })
      }
    },
    setValueCE(state, payload) {
      state.oldCE.value = payload
    },
    setInfoLead(state, payload) {
      state.infoLead.value = payload
    },
    setInfoBank(state, payload) {
      state.infoBank.value = payload
    },
    setIdLeadAssoc(state, payload) {
      state.idLeadAssoc.value = payload
    },
    setIdBankAssoc(state, payload) {
      state.idBankAssoc.value = payload
    },
    setEmpreAssoc(state, payload) {
      state.empreAssoc.value = payload
    },
    setIdUserSearch(state, payload) {
      state.idUserSearch.value = payload
    },
    setCurrentlyRent(state, payload) {
      state.currentlyRent.value = payload
    },
    setBlockBankOption(state, payload) {
      state.blockBankOption.value = payload
    },
    setInfoEmpreendimento(state, payload) {
      state.infoEmpreendimento.value = payload
    },
    setHistoryPricesListing(state, payload) {
      state.historyPricesListing.value = payload
    },
    setPreAngariacao(state, payload) {
      state.formPreAngariacao.value = payload
    },
    setCurrentState(state, payload) {
      state.currentState.value = payload
    },
    setForceWebsiteConfig(state, payload) {
      state.forceWebsiteConfig.value = payload
    },
    setShowVersionPending(state, payload) {
      state.showVersionPending.value = payload
    },
    setIdVersionRegViewer(state, payload) {
      state.idVersionRegViewer.value = payload
    },
    setDiasVendido(state, payload) {
      state.diasVendido.value = payload
    },
    setUrlWebsite(state, payload) {
      state.urlWebsite.value = payload
    },
    setUrlWebsiteLuxury(state, payload) {
      state.urlWebsiteLuxury.value = payload
    },
    setUrlWebsiteImovirtual(state, payload) {
      state.urlWebsiteImovirtual.value = payload
    },
    setUrlWebsiteListglobally(state, payload) {
      state.urlWebsiteListglobally.value = payload
    },
    setUrlWebsiteCryptos(state, payload) {
      state.urlWebsiteCryptos.value = payload
    },
    setUrlWebsiteIdealista(state, payload) {
      state.urlWebsiteIdealista.value = payload
    },
    setUrlWebsiteCasayes(state, payload) {
      state.urlWebsiteCasayes.value = payload
    },
    setLuxurySelect(state, payload) {
      state.bLuxurySelectable.value = payload
    },
    setCryptoSelect(state, payload) {
      state.bCryptoSelectable.value = payload
    },
    setStateImovirtual(state, payload) {
      state.stateImovirtual.value = payload
    },
    setErrorMessage(state, payload) {
      state.imovirtualErrorMessage.value = payload
    },
    setModerateMessage(state, payload) {
      state.imovirtualModerateMessage.value = payload
    },
    setIdealistaErrorMessage(state, payload) {
      state.idealistaError.value = payload
    },
    setCasayesErrorMessage(state, payload) {
      state.casayesError.value = payload
    },
    setShowVersionReg(state, payload) {
      state.showVersionReg.value = payload
    },
    setAprovaListing(state, payload) {
      state.aprovaListing.value = payload
    },
    setTemEstudoMercado(state, payload) {
      state.temEstudoMercado.value = payload
    },
    setUserEditListing(state, payload) {
      state.userChangeCanEditAng.value = payload
    },
    setEditListingHM(state, payload) {
      state.editListingHM.value = payload
    },
    setLinkInterno(state, payload) {
      state.linkInterno.value = payload
    },
    setPortals(state, payload) {
      state.portals.value = payload
    },
    setRules(state, payload) {
      state.rulesFields.value = payload
    },
    setAttrRules(state, payload) {
      state.rulesAttrFields.value = payload
    },
    setEspecialistas(state, payload) {
      state.especialistas.value = [...state.leader.value, ...payload]
    },
    setLeaderAng(state, payload) {
      state.leader.value = payload
    },
    setFilterChangeAgent(state, payload) {
      state.filterChangeAgent.value = payload
    },
    setLimitContratoMediacao(state, payload) {
      state.limitContratoMediacao.value = payload
    },
    setPlanoComunicacao(state, payload) {
      state.planoComunicacao.value = payload
    },
    setDisableRegimeContratacao(state, payload) {
      state.disableRegimeContratacao.value = payload
    },
    setIdsTypeListingVenture(state, payload) {
      state.idsTypeListingVenture.value = payload
    },
    setInvalidFields(state, payload) {
      state.invalidFields.value = payload
    },
    setCheckStatusApproveVersionReg(state, payload) {
      state.checkStatusApproveVersionReg.value = payload
    },
    setTotalImages(state, payload) {
      state.totalImages.value = payload
    },
    setCertificadoByOption(state, payload) {
      state.certificadoBy.value = payload
    },
  },
  actions: {

    // Atualiza a posição do tabulador do formulario
    setTabContentStepShow({ commit }, payload) {
      commit('setTabContentStepShow', payload)
    },

    // Carrega os dados base do formulario de criação
    async getDataFormBase({
      state, commit, rootGetters, dispatch,
    }, payload) {
      return new Promise((resolve, reject) => {
        let urlRequestData = 'listings/new'
        const { keyReg, statusP, page } = payload

        if (page === 'listings-pre-new') {
          if (typeof rootGetters['auth/currentUser'].extras.idLeader !== 'undefined') {
            urlRequestData = `listings/preNew/${btoa(rootGetters['auth/currentUser'].extras.idLeader)}`
          } else {
            urlRequestData = 'listings/preNew'
          }
        }

        if (state.modeFormDraft.value === 1) {
          urlRequestData = `listings/openDraft/${keyReg}`
        } else if ((keyReg !== undefined) && (keyReg !== '')) {
          urlRequestData = `listings/edit/${keyReg}`

          if (state.modeFormAction.value === 1) {
            if ((statusP !== undefined) && (statusP !== '')) {
              urlRequestData = `listings/viewDetail/${keyReg}/${statusP}`
            } else {
              urlRequestData = `listings/viewDetail/${keyReg}`
            }
          }
        }

        api.get(`${apiConfig.url_base_api}${urlRequestData}`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                if (typeof response.data.data.aLangApp !== 'undefined') {
                  response.data.data.aLangApp.unshift({ sw033s01: '0' })
                }

                if (typeof response.data.data.orientacaoSolar !== 'undefined') {
                  const solar = []

                  Object.entries(response.data.data.orientacaoSolar).forEach(element => {
                    solar.push({ id: element[0], desc: element[1] })
                  })

                  commit('setSolarOrientations', solar)
                }

                if (typeof response.data.data.dynamicIDForm !== 'undefined') {
                  commit('setDynamicIDForm', response.data.data.dynamicIDForm)
                }

                if (typeof response.data.data.idVersionReg !== 'undefined') {
                  commit('setIdVersionReg', response.data.data.idVersionReg)
                }

                if (typeof response.data.data.infoConsultor !== 'undefined') {
                  commit('setInfoAgent', response.data.data.infoConsultor)
                }

                if (typeof response.data.data.sW361ByHUBUser !== 'undefined') {
                  commit('setDescriptionScripts', response.data.data.sW361ByHUBUser)
                }

                if (typeof response.data.data.idConsultorSel !== 'undefined') {
                  commit('setIdAgent', response.data.data.idConsultorSel)
                }

                if (typeof response.data.data.leaderAngariacao !== 'undefined') {
                  commit('setLeaderAng', response.data.data.leaderAngariacao)
                }

                if (typeof response.data.data.especialistasAngariacao !== 'undefined') {
                  commit('setEspecialistas', response.data.data.especialistasAngariacao)
                }

                if (typeof response.data.data.totalImages !== 'undefined') {
                  commit('setTotalImages', response.data.data.totalImages)
                }

                commit('setAppConfig', response.data.data.configsAPP.app)
                commit('setLuxury', response.data.data.iLuxurySelectable)
                commit('setCrypto', response.data.data.iCryptoSelectable)
                commit('setIdAgentLead', response.data.data.idUserSearchLeadsAng)
                commit('setInfoMkt', response.data.data.infoMkt)
                commit('setUsersAgents', response.data.data.UsersConsultoresMkt)
                commit('setLanguages', response.data.data.aLangApp)
                commit('setBanks', response.data.data.aListBanks)
                commit('setEnergy', response.data.data.classes_energeticas)
                commit('setTypologiesClasses', response.data.data.classes_tipologias)
                commit('setConditions', response.data.data.condicao_imovel)
                commit('setDistricts', response.data.data.distritos)
                commit('setEnterprises', response.data.data.empreendimentos)
                commit('setListingsStates', response.data.data.estados_listing)
                commit('setFinality', response.data.data.finalidade)
                commit('setFloors', response.data.data.localizacaoAndares)
                commit('setTypeBusiness', response.data.data.tipo_negocio)
                commit('setTypeLand', response.data.data.tipo_terreno)
                commit('setTypologies', response.data.data.tipologias)
                commit('setTypeCategory', response.data.data.tiposImoveisCategoria)
                commit('setAttributes', response.data.data.detailAttrListing)
                commit('setPreviewPhotos', response.data.data.previewPhotos)
                commit('setIdsTypeListingVenture', response.data.data?.idsTypeListingVenture || '')
                commit('setCheckStatusApproveVersionReg', Number(response.data.data?.checkStatusApproveVersionReg || 0))

                if (typeof response.data.data.formPreAngariacao !== 'undefined') {
                  commit('setPreAngariacao', response.data.data.formPreAngariacao)

                  state.listingStates.value.push({ sw018s01: '9', sw018s02: i18n.t('Pré-angariação') })
                }

                if (typeof response.data.data.sw012 !== 'undefined') {
                  commit('setSW012', response.data.data.sw012)
                }

                if (typeof response.data.data.imagePreviewListing !== 'undefined') {
                  commit('setPreviewImage', response.data.data.imagePreviewListing)
                } else if (typeof response.data.data.imagePreviewImovel !== 'undefined') {
                  commit('setPreviewImage', response.data.data.imagePreviewImovel)
                } else {
                  commit('setPreviewImage', response.data.data.previewPhotos.listing)
                }

                if (typeof response.data.data.periodoArrendamento !== 'undefined') {
                  commit('setRentPeriod', response.data.data.periodoArrendamento)
                }

                if (typeof response.data.data.blockBankOption !== 'undefined') {
                  commit('setBlockBankOption', response.data.data.blockBankOption)
                }

                if (typeof response.data.data.showVersionPending !== 'undefined') {
                  commit('setShowVersionPending', response.data.data.showVersionPending)
                }

                if (typeof response.data.data.idVersionRegViewer !== 'undefined') {
                  commit('setIdVersionRegViewer', response.data.data.idVersionRegViewer)
                }

                if (typeof response.data.data.showVersionReg !== 'undefined') {
                  commit('setShowVersionReg', response.data.data.showVersionReg)
                }

                if (typeof response.data.data.aprovaListingHM !== 'undefined') {
                  commit('setAprovaListing', response.data.data.aprovaListingHM)
                }

                if (typeof response.data.data.temEstudoMercado !== 'undefined') {
                  commit('setTemEstudoMercado', response.data.data.temEstudoMercado)
                }

                if (typeof response.data.data.userChangeCanEditAng !== 'undefined') {
                  commit('setUserEditListing', response.data.data.userChangeCanEditAng)
                }

                if (typeof response.data.data.editListingHM !== 'undefined') {
                  commit('setEditListingHM', response.data.data.editListingHM)
                }

                if (typeof response.data.data.sw365s01 !== 'undefined') {
                  commit('setLinkInterno', response.data.data.sw365s01)
                }

                if (typeof response.data.data.contratoMediacaoLimit_90_dias !== 'undefined') {
                  commit('setLimitContratoMediacao', response.data.data.contratoMediacaoLimit_90_dias)
                }

                if (typeof response.data.data.certificadoBy !== 'undefined') {
                  commit('setCertificadoByOption', response.data.data.certificadoBy)
                }

                commit('setInitFiltersAttributes')

                commit('setFormDataSW012', {})
                // Modo de edição
                if ('sw012' in response.data.data) {
                  if (typeof response.data.data.sw012.sw012s01 !== 'undefined') {
                    commit('setKeyReg', btoa(response.data.data.sw012.sw012s01))
                  }

                  if (typeof response.data.data.dynamicIDVersion !== 'undefined') {
                    commit('setDynamicIDVersion', response.data.data.dynamicIDVersion)
                  }

                  // Adiciona a variavel geral para tratamento dos dados
                  const oSW012 = response.data.data.sw012

                  commit('setFormDataSW012', oSW012)
                  commit('setCurrentState', oSW012.sw012s64)

                  if ('sw012s135' in oSW012 && oSW012.sw012s135 !== null && oSW012.sw012s135 !== '') {
                    const aTagsAreas = oSW012.sw012s135.split(',')
                    commit('setUpdateFormDataField', { field: 'sw012s135', value: aTagsAreas })
                  }

                  if ('sw012s129' in oSW012 && oSW012.sw012s129 !== null && oSW012.sw012s129 !== '') {
                    const aTagsAreas = oSW012.sw012s129.split(',')
                    commit('setUpdateFormDataField', { field: 'sw012s129', value: aTagsAreas })
                  }

                  if ('sw012s316' in oSW012 && oSW012.sw012s316 !== null && oSW012.sw012s316 !== '') {
                    const aTagsAreas = oSW012.sw012s316.split(',')
                    commit('setUpdateFormDataField', { field: 'sw012s316', value: aTagsAreas })
                  }

                  if ('sw012s320' in oSW012 && oSW012.sw012s320 !== null && oSW012.sw012s320 !== '') {
                    const aTagsAreas = oSW012.sw012s320.split(',')
                    commit('setUpdateFormDataField', { field: 'sw012s320', value: aTagsAreas })
                  }

                  if ('sw012s127' in oSW012 && oSW012.sw012s127 !== null && oSW012.sw012s127 !== '') {
                    const aTagsAreas = oSW012.sw012s127.split(',')
                    commit('setUpdateFormDataField', { field: 'sw012s127', value: aTagsAreas })
                  }

                  if ('sw012s148' in oSW012 && oSW012.sw012s148 !== null && oSW012.sw012s148 !== '') {
                    const aTagsAreas = oSW012.sw012s148.split(',')
                    commit('setUpdateFormDataField', { field: 'sw012s148', value: aTagsAreas })
                  }

                  if ('sw012s141' in oSW012 && oSW012.sw012s141 !== null && oSW012.sw012s141 !== '') {
                    const aTagsAreas = oSW012.sw012s141.split(',')
                    commit('setUpdateFormDataField', { field: 'sw012s141', value: aTagsAreas })
                  }

                  if ('sw012s131' in oSW012 && oSW012.sw012s131 !== null && oSW012.sw012s131 !== '') {
                    const aTagsAreas = oSW012.sw012s131.split(',')
                    commit('setUpdateFormDataField', { field: 'sw012s131', value: aTagsAreas })
                  }

                  if ('sw012s128' in oSW012 && oSW012.sw012s128 !== null && oSW012.sw012s128 !== '') {
                    const aTagsAreas = oSW012.sw012s128.split(',')
                    commit('setUpdateFormDataField', { field: 'sw012s128', value: aTagsAreas })
                  }

                  if ('sw012s130' in oSW012 && oSW012.sw012s130 !== null && oSW012.sw012s130 !== '') {
                    const aTagsAreas = oSW012.sw012s130.split(',')
                    commit('setUpdateFormDataField', { field: 'sw012s130', value: aTagsAreas })
                  }

                  if ('sw012s543' in oSW012 && oSW012.sw012s543 !== null && oSW012.sw012s543 !== '') {
                    const oVentureSel = state.enterprises.value.find(o => Number(o.id) === Number(oSW012.sw012s543))
                    commit('setUpdateFormDataField', { field: 'sw012s543', value: oVentureSel })
                  }

                  commit('setUpdateFormDataField', { field: 'descTipologia', value: response.data.data.descTipologia })
                  commit('setListingTradDesc', response.data.data.aListTradListings)

                  if (typeof response.data.data.infoDataLead !== 'undefined') {
                    commit('setInfoLead', response.data.data.infoDataLead)
                  }

                  if (typeof response.data.data.infoDataBank !== 'undefined') {
                    commit('setInfoBank', response.data.data.infoDataBank)
                  }

                  if (typeof response.data.data.idLeadAssoc !== 'undefined') {
                    commit('setIdLeadAssoc', response.data.data.idLeadAssoc)
                  }

                  if (response.data.data.idLeadAssoc === '' && typeof response.data.data.infoDataLead !== 'undefined' && response.data.data.infoDataLead !== '') {
                    commit('setIdLeadAssoc', btoa(response.data.data.infoDataLead.sw025s01))
                  }
                  commit('setIdBankAssoc', response.data.data.idBankAssoc)
                  commit('setEmpreAssoc', response.data.data.empreendimentoAssoc)
                  commit('setIdUserSearch', response.data.data.idUserSearchLeadsAng)

                  if (typeof response.data.data.designationId !== 'undefined') {
                    commit('setUpdateFormDataField', {
                      field: 'sw012s02_manual',
                      value: response.data.data.designationId,
                    })
                    commit('setUpdateFormData', state.form_data.value)
                  }

                  if (typeof response.data.data.infoEmpreendimento !== 'undefined') {
                    commit('setInfoEmpreendimento', response.data.data.infoEmpreendimento)
                  }

                  if (typeof response.data.data.historyPricesListing !== 'undefined') {
                    commit('setHistoryPricesListing', response.data.data.historyPricesListing)
                  }

                  if (typeof response.data.data.diasVendido !== 'undefined') {
                    commit('setDiasVendido', response.data.data.diasVendido)
                  }

                  if (typeof response.data.data.iLuxurySelectable !== 'undefined') {
                    commit('setLuxurySelect', response.data.data.iLuxurySelectable)
                  }

                  if (typeof response.data.data.iCryptoSelectable !== 'undefined') {
                    commit('setCryptoSelect', response.data.data.iCryptoSelectable)
                  }

                  if (typeof response.data.data.urlWebsite !== 'undefined') {
                    commit('setUrlWebsite', response.data.data.urlWebsite)
                  }

                  if (typeof response.data.data.urlWebsiteLuxury !== 'undefined') {
                    commit('setUrlWebsiteLuxury', response.data.data.urlWebsiteLuxury)
                  }

                  if (typeof response.data.data.urlWebsiteImovirtual !== 'undefined') {
                    commit('setUrlWebsiteImovirtual', response.data.data.urlWebsiteImovirtual)
                  }

                  if (typeof response.data.data.urlWebsiteListglobally !== 'undefined') {
                    commit('setUrlWebsiteListglobally', response.data.data.urlWebsiteListglobally)
                  }

                  if (typeof response.data.data.urlWebsiteCryptos !== 'undefined') {
                    commit('setUrlWebsiteCryptos', response.data.data.urlWebsiteCryptos)
                  }

                  if (typeof response.data.data.urlWebsiteIdealista !== 'undefined') {
                    commit('setUrlWebsiteIdealista', response.data.data.urlWebsiteIdealista)
                  }

                  if (typeof response.data.data.urlWebsiteCasayes !== 'undefined') {
                    commit('setUrlWebsiteCasayes', response.data.data.urlWebsiteCasayes)
                  }

                  if (typeof response.data.data.estadoImovirtualApi !== 'undefined') {
                    commit('setStateImovirtual', response.data.data.estadoImovirtualApi)
                  }

                  if (typeof response.data.data.imovirtualError !== 'undefined') {
                    commit('setErrorMessage', response.data.data.imovirtualError)
                  }

                  if (typeof response.data.data.imovirtualModerateMessage !== 'undefined') {
                    commit('setModerateMessage', response.data.data.imovirtualModerateMessage)
                  }

                  if (typeof response.data.data.idealistaError !== 'undefined') {
                    commit('setIdealistaErrorMessage', response.data.data.idealistaError)
                  }

                  if (typeof response.data.data.casayesError !== 'undefined') {
                    commit('setCasayesErrorMessage', response.data.data.casayesError)
                  }

                  if (typeof response.data.data.sw298 !== 'undefined') {
                    commit('setPlanoComunicacao', response.data.data.sw298)
                  }

                  if (state.modeFormAction.value === 1) {
                    response.data.data.detailAttrListing.extras.forEach(element => {
                      if (oSW012[element.id] === '1') {
                        commit('setShowExtras', true)
                      }
                    })

                    response.data.data.detailAttrListing.vistas.forEach(element => {
                      if (oSW012[element.id] === '1') {
                        commit('setShowVistas', true)
                      }
                    })

                    response.data.data.detailAttrListing.energia.forEach(element => {
                      if (oSW012[element.id] === '1') {
                        commit('setShowEnergia', true)
                      }
                    })

                    response.data.data.detailAttrListing.seguranca.forEach(element => {
                      if (oSW012[element.id] === '1') {
                        commit('setShowSeguranca', true)
                      }
                    })

                    response.data.data.detailAttrListing.espacos.forEach(element => {
                      if (oSW012[element.id] === '1') {
                        commit('setShowEspacos', true)
                      }
                    })

                    response.data.data.detailAttrListing.exterior.forEach(element => {
                      if (oSW012[element.id] === '1') {
                        commit('setShowExterior', true)
                      }
                    })

                    if (typeof response.data.data.detailAttrListing.localizacao !== 'undefined') {
                      response.data.data.detailAttrListing.localizacao.forEach(element => {
                        if (oSW012[element.id] === '1') {
                          commit('setShowLocalizacao', true)
                        }
                      })
                    }
                  }

                  if (typeof response.data.data.cadernetasPrediais !== 'undefined') {
                    response.data.data.cadernetasPrediais.forEach(element => {
                      let valor = element.sw078s07
                      if (state.modeFormAction.value !== 1) {
                        valor = element.sw078s07.replace('.', '')
                        valor = element.sw078s07.replace(',', '.')
                      }

                      state.form_sw078.value.push({
                        sw078s05: element.sw078s05,
                        sw078s06: element.sw078s06,
                        sw078s07: valor,
                      })
                    })
                  }

                  if (typeof response.data.data.conservatoriasRegistoPredial !== 'undefined') {
                    response.data.data.conservatoriasRegistoPredial.forEach(element => {
                      state.form_sw086.value.push({
                        sw086s05: element.sw086s05,
                        sw086s06: element.sw086s06,
                        sw086s07: element.sw086s07,
                      })
                    })
                  }

                  if (typeof oSW012.sw012s379 !== 'undefined' && oSW012.sw012s379 !== null && Number(oSW012.sw012s379) === 1) {
                    state.listingStates.value.forEach((element, index) => {
                      if (Number(element.sw018s01) === 5) {
                        state.listingStates.value[index].sw018s02 = i18n.t('Externa')
                      }
                    })
                  }

                  if (typeof oSW012.sw012s376 !== 'undefined' && oSW012.sw012s376 !== null && Number(oSW012.sw012s376) === 1) {
                    dispatch('calculateRentabilidadeBruta')
                  }
                }

                if (typeof response.data.data.subTiposImoveis !== 'undefined' && typeof response.data.data.subTiposImoveisSel !== 'undefined') {
                  commit('setSubTipoImoveis', response.data.data.subTiposImoveis)
                  commit('setSubTipoImoveisSel', response.data.data.subTiposImoveisSel)

                  const aTiposImoveis = []
                  response.data.data.subTiposImoveisSel.forEach(element => {
                    const oValue = response.data.data.subTiposImoveis.find(o => Number(o.sw004s01) === Number(element))
                    if (oValue) {
                      aTiposImoveis.push(oValue)
                    }
                  })

                  commit('setUpdateFormDataField', { field: 'sw019s03', value: aTiposImoveis })
                }

                resolve(true)
              } else if (response.data.hookAlertDialogMessage !== undefined) {
                reject(new Error(response.data.hookAlertDialogMessage))
              } else {
                reject(new Error(i18n.t('Problema ao carregar o formulário')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o formulário')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o formulário')))
            }
          })
      })
    },

    // Save formulário
    async saveSW012({ state, dispatch }) {
      const formData = await dispatch('formSerialize')

      if (state.loadTabMedia.value === true) {
        formData.append('fLoadImages', 1)
        formData.append('fLoadVideos', 1)
        formData.append('fLoadRealidadeVirtual', 1)
        formData.append('fLoadImagesPrincipais', 1)
      }

      if (state.loadTabPrivado.value === true) {
        formData.append('fLoadDocsPublic', 1)
        formData.append('fLoadDocsPrivate', 1)
        formData.append('fLoadImagesPrivate', 1)
        formData.append('fLoadNotes', 1)
      }

      if (state.loadTabAtividade.value === true) {
        formData.append('fLoadGestIDs', 1)
        formData.append('fLoadGestLinks', 1)
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/save`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao gravar a angariação')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao gravar a angariação')))
            }
          })
      })
    },

    // Auto-save formulário
    async autosaveSW012({ state, dispatch }) {
      const formData = await dispatch('formSerialize')

      if (state.loadTabMedia.value === true) {
        formData.append('fLoadImages', 1)
        formData.append('fLoadVideos', 1)
        formData.append('fLoadRealidadeVirtual', 1)
        formData.append('fLoadImagesPrincipais', 1)
      }

      if (state.loadTabPrivado.value === true) {
        formData.append('fLoadDocsPublic', 1)
        formData.append('fLoadDocsPrivate', 1)
        formData.append('fLoadImagesPrivate', 1)
        formData.append('fLoadNotes', 1)
      }

      if (state.loadTabAtividade.value === true) {
        formData.append('fLoadGestIDs', 1)
        formData.append('fLoadGestLinks', 1)
      }

      return new Promise(resolve => {
        dispatch('request/cancelTokensPendingByUrl', 'listings/autoSaveRec', { root: true })

        api.post(`${apiConfig.url_base_api}listings/autoSaveRec`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            }
          })
      })
    },

    // Apaga o registo do rascunho do registo
    async cancelChangesDraftUser({ state }) {
      const formData = new FormData()

      if (typeof state.keyReg.value !== 'undefined') {
        formData.append('keyReg', state.keyReg.value)
      }

      formData.append('dynamicIDForm', state.dynamicIDForm.value)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/cancelChangesDraftUser`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response)
            } else {
              reject(new Error(i18n.t('Problema ao cancelar registo')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao cancelar registo')))
            }
          })
      })
    },

    // Verificar imagens da angariação
    async checkTotalImagesByListing({ state }) {
      const formData = new FormData()

      if (typeof state.keyReg.value !== 'undefined') {
        formData.append('keyReg', state.keyReg.value)
      }

      formData.append('keyDynamic', state.dynamicIDForm.value)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/checkTotalImagesByListing`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao verificar imagens da angariação')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao verificar imagens da angariação')))
            }
          })
      })
    },

    // Verificar regras do formulário
    async checkValidateRulesForm({ state, commit }, payload) {
      const formData = new FormData()

      formData.append('type', payload.type)
      formData.append('consultor', payload.consultor)
      formData.append('lead', payload.lead)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/checkValidateRulesForm`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              commit('setUpdateFormData', state.form_data.value)
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao verificar regras do formulário')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao verificar regras do formulário')))
            }
          })
      })
    },

    // Carrega os dados dos tipos de imóveis por categoria
    async getTipoImoveisByCategoria({ commit }, payload) {
      const formData = new FormData()

      formData.append('sw004s08', payload)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/getComboTipoImoveisByCategoria`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              const aTypeListings = []
              Object.keys(response.data.imoveis).forEach(index => {
                aTypeListings.push(response.data.imoveis[index])
              })

              commit('setTypeListings', aTypeListings)
              resolve(true)
            } else {
              reject(new Error(i18n.t('Problema ao obter as categorias dos imóveis')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter as categorias dos imóveis')))
            }
          })
      })
    },

    // Carrega os dados dos sub tipos de imóveis
    async getSubTipoImoveis({ commit }, payload) {
      const formData = new FormData()

      formData.append('sw004s09', payload)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/getSubTipoImoveis`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              const aSubTypeListings = []
              Object.keys(response.data.tipos).forEach(index => {
                aSubTypeListings.push(response.data.tipos[index])
              })

              commit('setSubTypeListings', aSubTypeListings)
              resolve(true)
            } else {
              reject(new Error(i18n.t('Problema ao obter as categorias dos imóveis')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter as categorias dos imóveis')))
            }
          })
      })
    },

    // Carrega as tipologias extras
    async getTipologiasExtras({ commit }, payload) {
      const formData = new FormData()

      formData.append('sw005s09', payload)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}tipologias/getExtras`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              const aTipologiasExtras = []
              Object.keys(response.data.tipologias).forEach(index => {
                aTipologiasExtras.push(response.data.tipologias[index])
              })

              commit('setTypologiesExtra', aTipologiasExtras)
              resolve(true)
            } else {
              reject(new Error(i18n.t('Problema ao obter as tipologias extras')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter as tipologias extras')))
            }
          })
      })
    },

    // Carrega o valor do income PAA
    async getValueMaxIncomePAA({ state, commit, dispatch }, payload) {
      const formData = new FormData()

      formData.append('sw012s231', payload.sw012s231)
      formData.append('sw012s241', payload.sw012s241)
      formData.append('sw012s467', payload.sw012s467)

      return new Promise((resolve, reject) => {
        dispatch('request/cancelTokensPendingByUrl', 'listings/getValueMaxIncomePAA', { root: true })

        api.post(`${apiConfig.url_base_api}listings/getValueMaxIncomePAA`, formData)
          .then(response => {
            if (typeof response.data === 'object' && Number(response.data.error) !== 1) {
              commit('setUpdateFormDataField', { field: 'sw012s242', value: response.data.value })
              commit('setUpdateFormData', state.form_data.value)
              resolve(true)
            } else {
              commit('setUpdateFormDataField', { field: 'sw012s231', value: null })
              commit('setUpdateFormDataField', { field: 'sw012s241', value: null })
              commit('setUpdateFormDataField', { field: 'sw012s242', value: null })
              commit('setUpdateFormData', state.form_data.value)
              reject(new Error(response.data.msg))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter valor do income PAA')))
            }
          })
      })
    },

    // Validação da data do CMI
    async checkDatesCMI_EstadoCancelado({ dispatch }, payload) {
      const formData = new FormData()

      formData.append('dataHome', payload.dataHome)
      formData.append('numbMonth', payload.numbMonth)

      return new Promise((resolve, reject) => {
        dispatch('request/cancelTokensPendingByUrl', 'listings/checkDatesCMI_EstadoCancelado', { root: true })

        api.post(`${apiConfig.url_base_api}listings/checkDatesCMI_EstadoCancelado`, formData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao validar as datas')))
            }
          })
      })
    },

    // Calcula a data de fim do Contrato de mediação
    async addMonthDate({ state, commit, rootGetters }, payload) {
      const formData = new FormData()

      formData.append('dataHome', payload.dataHome)
      formData.append('month', payload.month)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/addMonthDate`, formData)
          .then(response => {
            if (typeof response.data === 'object' && Number(response.data.error) !== 1) {
              if (payload.fReturn === 1) {
                resolve(response)
              } else {
                commit('setUpdateFormDataField', { field: 'sw012s18', value: response.data.date })
                commit('setUpdateFormData', state.form_data.value)

                if (rootGetters['auth/countryApp'] === 'ES' || (rootGetters['auth/countryApp'] === 'AR' && payload.month === 0)) {
                  // Define a data final como o limite da data de inicio
                  if (response.data.date) {
                    // dateEnd.datepicker('setStartDate', new Date(vlData));
                  }
                }

                resolve(true)
              }
            } else {
              reject(new Error(i18n.t('Problema a calcular a data de fim do contrado de mediação')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a calcular a data de fim do contrado de mediação')))
            }
          })
      })
    },

    // Valida proposta na angariação
    async checkPropostaAng({ state }, payload) {
      const formData = new FormData()

      formData.append('sw012s28', state.keyReg.value)
      formData.append('action', payload.action)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/checkPropostaAng`, formData)
          .then(response => {
            if (response.data.check === false) {
              reject(new Error(response.data.msg))
            } else {
              resolve(true)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a calcular a data de fim do contrado de mediação')))
            }
          })
      })
    },

    // Carrega os dados para o painel dos Portais
    async getModalWebsiteStatus({ state, commit, rootGetters }, payload) {
      const formData = new FormData()

      formData.append('keyReg', state.keyReg.value)
      formData.append('keyDynamic', state.dynamicIDForm.value)
      formData.append('modeForm', state.modeFormAction.value)
      formData.append('versionDynamic', state.dynamicIDVersion.value)
      formData.append('versionReg', state.idVersionReg.value)

      formData.append('sw012s02', state.form_data.value.sw012s02)
      formData.append('sw012s04', state.idAgent.value)
      formData.append('sw012s06', (typeof state.form_data.value.sw012s06 !== 'undefined' && state.form_data.value.sw012s06 !== null ? Number(state.form_data.value.sw012s06.sw007s01) : ''))
      formData.append('sw012s07', (typeof state.form_data.value.sw012s07 !== 'undefined' && state.form_data.value.sw012s07 !== null ? Number(state.form_data.value.sw012s07.id) : ''))
      formData.append('sw012s08', (typeof state.form_data.value.sw012s08 !== 'undefined' && state.form_data.value.sw012s08 !== null ? Number(state.form_data.value.sw012s08.sw005s01) : ''))
      formData.append('sw012s08_plus', (typeof state.form_data.value.sw012s08_plus !== 'undefined' && state.form_data.value.sw012s08_plus !== null ? Number(state.form_data.value.sw012s08_plus.sw005s01) : ''))
      formData.append('sw012s09', state.form_data.value.sw012s09)
      formData.append('sw012s14', state.form_data.value.sw012s14)
      formData.append('sw012s19', (typeof state.form_data.value.sw012s19 !== 'undefined' && state.form_data.value.sw012s19 !== null ? Number(state.form_data.value.sw012s19.sw006s01) : ''))
      formData.append('sw012s27', state.form_data.value.sw012s27)
      formData.append('sw012s28', state.form_data.value.sw012s28)
      formData.append('sw012s33', state.form_data.value.sw012s33)
      formData.append('sw012s34', state.form_data.value.sw012s34)
      formData.append('sw012s35', state.form_data.value.sw012s35)
      formData.append('sw012s48', state.form_data.value.sw012s48)
      formData.append('sw012s50', state.form_data.value.sw012s50)
      formData.append('sw012s51', state.form_data.value.sw012s51)
      formData.append('sw012s65', state.form_data.value.sw012s65)
      formData.append('sw012s67', state.form_data.value.sw012s67)
      formData.append('sw012s69', state.form_data.value.sw012s69)
      formData.append('sw012s67', state.form_data.value.sw012s67)
      formData.append('sw012s298', state.form_data.value.sw012s298)
      formData.append('sw012s312', (typeof state.form_data.value.sw012s312 !== 'undefined' && state.form_data.value.sw012s312 !== null ? Number(state.form_data.value.sw012s312.sw087s01) : ''))
      formData.append('sw012s316', state.form_data.value.sw012s316)
      formData.append('sw012s331', state.form_data.value.sw012s331)
      formData.append('sw012s377', state.form_data.value.sw012s377)
      formData.append('sw012s536', (typeof state.form_data.value.sw012s536 !== 'undefined' && state.form_data.value.sw012s536 !== null ? Number(state.form_data.value.sw012s536.sw361s09) : ''))

      if ([4, 7].includes(Number(rootGetters['auth/currentUser'].id_role)) && typeof state.form_data.value.sw012s64 !== 'undefined' && state.form_data.value.sw012s64 !== null && [4, 6, 8].includes(Number(state.form_data.value.sw012s64))) {
        formData.append('sw012s64', 5)
      } else {
        formData.append('sw012s64', (typeof state.form_data.value.sw012s64 !== 'undefined' && state.form_data.value.sw012s64 !== null ? Number(state.form_data.value.sw012s64.sw018s01) : ''))
      }

      /// identificação se tem uma imagem com a tag planta
      let tagPlanta = false
      rootGetters['sw014/images'].forEach(item => {
        if (typeof item.id_tag !== 'undefined' && Number(item.id_tag.sw286s01) === 1 && tagPlanta === false) {
          formData.append('sw012_planta', 1)
          tagPlanta = true
        }
      })

      if (state.loadTabMedia.value === true) {
        formData.append('sw012_imagensLoaded', 1)
        formData.append('fLoadImages', 1)
      } else {
        formData.append('fLoadImages', 0)
      }

      if (payload.description !== '' && payload.description.short !== null && payload.description.short !== '') {
        formData.append('sw012s34_en', payload.description.short)
      }

      if (payload.description !== '' && payload.description.complete !== null && payload.description.complete !== '') {
        formData.append('sw012s35_en', payload.description.complete)
      }

      if (payload.description !== '' && payload.description.nameWebsite !== null && payload.description.nameWebsite !== '') {
        formData.append('sw012s67_en', payload.description.nameWebsite)
      }

      if (rootGetters['auth/countryApp'] === 'AR') {
        formData.append('sw012s29', state.form_data.value.sw012s29)
        formData.append('sw012s30', state.form_data.value.sw012s30)
        formData.append('sw012s31', state.form_data.value.sw012s31)
        formData.append('sw012s35', state.form_data.value.sw012s35)
        formData.append('sw012s467', state.form_data.value.sw012s467)
        formData.append('sw012s516', state.form_data.value.sw012s516)
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/getModalWebsiteStatus`, formData)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              if (typeof response.data.data.portals !== 'undefined') {
                commit('setPortals', response.data.data.portals)
              }

              if (typeof response.data.data.diasVendido !== 'undefined') {
                commit('setDiasVendido', response.data.data.diasVendido)
              }

              if (typeof response.data.data.iLuxurySelectable !== 'undefined') {
                commit('setLuxurySelect', response.data.data.iLuxurySelectable)
              }

              if (typeof response.data.data.iCryptoSelectable !== 'undefined') {
                commit('setCryptoSelect', response.data.data.iCryptoSelectable)
              }

              if (typeof response.data.data.urlWebsite !== 'undefined') {
                commit('setUrlWebsite', response.data.data.urlWebsite)
              }

              if (typeof response.data.data.urlWebsiteLuxury !== 'undefined') {
                commit('setUrlWebsiteLuxury', response.data.data.urlWebsiteLuxury)
              }

              if (typeof response.data.data.urlWebsiteImovirtual !== 'undefined') {
                commit('setUrlWebsiteImovirtual', response.data.data.urlWebsiteImovirtual)
              }

              if (typeof response.data.data.urlWebsiteListglobally !== 'undefined') {
                commit('setUrlWebsiteListglobally', response.data.data.urlWebsiteListglobally)
              }

              if (typeof response.data.data.urlWebsiteCryptos !== 'undefined') {
                commit('setUrlWebsiteCryptos', response.data.data.urlWebsiteCryptos)
              }

              if (typeof response.data.data.urlWebsiteIdealista !== 'undefined') {
                commit('setUrlWebsiteIdealista', response.data.data.urlWebsiteIdealista)
              }

              if (typeof response.data.data.urlWebsiteCasayes !== 'undefined') {
                commit('setUrlWebsiteCasayes', response.data.data.urlWebsiteCasayes)
              }

              if (typeof response.data.data.estadoImovirtualApi !== 'undefined') {
                commit('setStateImovirtual', response.data.data.estadoImovirtualApi)
              }

              if (typeof response.data.data.imovirtualError !== 'undefined') {
                commit('setErrorMessage', response.data.data.imovirtualError)
              }

              if (typeof response.data.data.idealistaError !== 'undefined') {
                commit('setIdealistaErrorMessage', response.data.data.idealistaError)
              }

              if (typeof response.data.data.casayesError !== 'undefined') {
                commit('setCasayesErrorMessage', response.data.data.casayesError)
              }

              if (typeof response.data.data.imovirtualModerateMessage !== 'undefined') {
                commit('setModerateMessage', response.data.data.imovirtualModerateMessage)
              }

              resolve(true)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a carregar painel dos portais externos')))
            }
          })
      })
    },

    // Gravação dos dados dos portais externos
    async changeWebsiteStatus({ state }) {
      const formData = new FormData()

      formData.append('keyReg', state.keyReg.value)
      formData.append('sw012s333', (typeof state.form_data.value.sw012s333 !== 'undefined' && state.form_data.value.sw012s333 !== null ? Number(state.form_data.value.sw012s333) : 3))
      formData.append('sw012s372', (typeof state.form_data.value.sw012s372 !== 'undefined' && state.form_data.value.sw012s372 !== null ? Number(state.form_data.value.sw012s372) : 3))
      formData.append('sw012s547', (typeof state.form_data.value.sw012s547 !== 'undefined' && state.form_data.value.sw012s547 !== null ? Number(state.form_data.value.sw012s547) : null))
      formData.append('sw012s375', (typeof state.form_data.value.sw012s375 !== 'undefined' && state.form_data.value.sw012s375 !== null ? Number(state.form_data.value.sw012s375) : 3))
      formData.append('sw012s382', (typeof state.form_data.value.sw012s382 !== 'undefined' && state.form_data.value.sw012s382 !== null ? Number(state.form_data.value.sw012s382) : 3))
      formData.append('sw012s383', (typeof state.form_data.value.sw012s383 !== 'undefined' && state.form_data.value.sw012s383 !== null ? Number(state.form_data.value.sw012s383) : 3))
      formData.append('sw012s400', (typeof state.form_data.value.sw012s400 !== 'undefined' && state.form_data.value.sw012s400 !== null ? Number(state.form_data.value.sw012s400) : 3))
      formData.append('sw012s402', (typeof state.form_data.value.sw012s402 !== 'undefined' && state.form_data.value.sw012s402 !== null ? Number(state.form_data.value.sw012s402) : 3))
      formData.append('sw012s528', (typeof state.form_data.value.sw012s528 !== 'undefined' && state.form_data.value.sw012s528 !== null ? Number(state.form_data.value.sw012s528) : 3))
      formData.append('sw012s529', (typeof state.form_data.value.sw012s529 !== 'undefined' && state.form_data.value.sw012s529 !== null ? Number(state.form_data.value.sw012s529) : 3))
      formData.append('sw012s531', (typeof state.form_data.value.sw012s531 !== 'undefined' && state.form_data.value.sw012s531 !== null ? Number(state.form_data.value.sw012s531) : 3))
      formData.append('sw012s540', (typeof state.form_data.value.sw012s540 !== 'undefined' && state.form_data.value.sw012s540 !== null ? Number(state.form_data.value.sw012s540) : 3))
      formData.append('sw012s541', (typeof state.form_data.value.sw012s541 !== 'undefined' && state.form_data.value.sw012s541 !== null ? Number(state.form_data.value.sw012s541) : 3))
      formData.append('sw012s546', (typeof state.form_data.value.sw012s546 !== 'undefined' && state.form_data.value.sw012s546 !== null ? Number(state.form_data.value.sw012s546) : 3))
      formData.append('sw012s548', (typeof state.form_data.value.sw012s548 !== 'undefined' && state.form_data.value.sw012s548 !== null ? Number(state.form_data.value.sw012s548) : 3))

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/changeWebsiteStatus/${state.keyReg.value}`, formData)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a gravar alterações no painel dos portais externos')))
            }
          })
      })
    },

    // Carrega os dados para o painel do rating
    async previewModalRating({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/previewModalRating`, payload)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              commit('setForceWebsiteConfig', 1)
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a carregar painel do rating')))
            }
          })
      })
    },

    // Verifica se já tem plano de comunicação adicionado
    async checkPlanoComunicacao({ state }) {
      const formData = new FormData()

      formData.append('keyReg', state.keyReg.value)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/checkPlanoComunicacao`, formData)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao verificar se tem plano de comunicação associado')))
            }
          })
      })
    },

    // Carrega os planos de comunicação
    async loadListPlanosComunicacao({ commit }) {
      const formData = new FormData()

      formData.append('sw228s03', 5)
      formData.append('sw228s07', 1)
      formData.append('sw228s18', 4)
      formData.append('sw228s24', 1)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/loadListPlanosComunicacao`, formData)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              commit('setForceWebsiteConfig', 1)
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao verificar se tem plano de comunicação associado')))
            }
          })
      })
    },

    // Grava o plano de comunicação selecionado
    async saveModeloPlanoComunicacao({ state }, payload) {
      const formData = new FormData()

      formData.append('keyReg', state.keyReg.value)
      formData.append('idModelo', payload.idModelo)
      formData.append('idDynamic', state.dynamicIDForm.value)

      let idLead = state.idLeadAssoc.value
      if (typeof state.form_data.value.sw012s05Change !== 'undefined' && state.form_data.value.sw012s05Change !== null) {
        idLead = state.form_data.value.sw012s05Change
      }

      formData.append('idLead', idLead)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/saveModeloPlanoComunicacao`, formData)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao associar plano de comunicação a angariação')))
            }
          })
      })
    },

    // Carrega os dados do modal do contrado de mediação
    async getModalMediacao({ state }) {
      const formData = new FormData()

      if (state.formModeView === true) {
        formData.append('sw012s01', state.keyReg.value)
      } else {
        formData.append('sw012s01', state.keyReg.value)
        formData.append('p_sw012s17', state.form_data.value.sw012s17)
        formData.append('p_sw012s18', state.form_data.value.sw012s18)
        formData.append('p_sw012s68', state.form_data.value.sw012s68.id)
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/modalCreateNewContratoMediacao`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema ao carregar modal do contrado de mediação')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar modal do contrado de mediação')))
            }
          })
      })
    },

    // Carrega os dados para cancelar o contrado de mediação
    async getDatesContratoMediacao({ state }) {
      const formData = new FormData()

      formData.append('sw012s01', state.keyReg.value)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/getDatesContratoMediacao`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema ao carregar dados para cancelar contrado de mediação')))
            } else {
              resolve(response)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar dados para cancelar contrado de mediação')))
            }
          })
      })
    },

    // Cancelar o contrado de mediação
    async cancelarContratoMediacao({ state }, payload) {
      const formData = new FormData()

      formData.append('sw012s01', state.keyReg.value)
      formData.append('typeAction', payload.typeAction)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/cancelarContratoMediacao`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema ao cancelar contrado de mediação')))
            } else {
              resolve(response)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao cancelar contrado de mediação')))
            }
          })
      })
    },

    // Reativar o contrado de mediação
    async reativarContratoMediacao({ state }, payload) {
      const formData = new FormData()

      formData.append('sw012s01', state.keyReg.value)
      formData.append('sw012s17', payload.sw012s17)
      formData.append('sw012s18', payload.sw012s18)
      formData.append('sw012s68', payload.sw012s68)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/saveNewContratoMediacao`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema a reativar contrado de mediação')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a reativar contrado de mediação')))
            }
          })
      })
    },

    // Aprova/Recusa a versão da angariação
    async aprovaListing({ state }, payload) {
      const formData = new FormData()

      formData.append('sw012s01', state.keyReg.value)
      formData.append('type', payload.type)

      if (typeof payload.type !== 'undefined' && payload.type === 2) {
        formData.append('sw012s71', payload.sw012s71)
      } else if (typeof payload.type !== 'undefined' && payload.type === 1) {
        formData.append('sw012s333', state.form_data.value.sw012s333)
        formData.append('sw012s372', state.form_data.value.sw012s372)
        formData.append('sw012s375', state.form_data.value.sw012s375)
        formData.append('sw012s382', state.form_data.value.sw012s382)
        formData.append('sw012s383', state.form_data.value.sw012s383)
        formData.append('sw012s398', state.form_data.value.sw012s398)
        formData.append('sw012s400', state.form_data.value.sw012s400)
        formData.append('sw012s402', state.form_data.value.sw012s402)
        formData.append('sw012s512', state.form_data.value.sw012s512)
        formData.append('sw012s528', state.form_data.value.sw012s528)
        formData.append('sw012s529', state.form_data.value.sw012s529)
        formData.append('sw012s531', state.form_data.value.sw012s531)
        formData.append('sw012s540', state.form_data.value.sw012s540)
        formData.append('sw012s541', state.form_data.value.sw012s541)
        formData.append('sw012s546', state.form_data.value.sw012s546)
        formData.append('sw012s548', state.form_data.value.sw012s548)
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/aprovaListing`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              if (typeof payload.type !== 'undefined' && payload.type === 2) {
                reject(new Error(i18n.t('Problema a recusar a versão pendente da angariação')))
              } else {
                reject(new Error(i18n.t('Problema a aprovar a versão pendente da angariação')))
              }
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              if (typeof payload.type !== 'undefined' && payload.type === 2) {
                reject(new Error(i18n.t('Problema a recusar a versão pendente da angariação')))
              } else {
                reject(new Error(i18n.t('Problema a aprovar a versão pendente da angariação')))
              }
            }
          })
      })
    },

    // Reativar o contrado de mediação
    async getScriptsVenda({ commit }, payload) {
      const formData = new FormData()

      formData.append('sw012s04', payload.sw012s04)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/getScriptsVenda`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema obter o scripts de venda')))
            } else {
              commit('setDescriptionScripts', response.data.scripts)
              resolve(true)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema obter o scripts de venda')))
            }
          })
      })
    },

    async getCriteriosLuxury({ state }) {
      const url = `${apiConfig.url_base_api}listings/getCriteriosLuxury`

      let params = `?sw012s01=${state.keyReg.value}`

      let idUser = state.idAgent.value
      if (typeof state.form_data.value.sw012s04 !== 'undefined' && state.form_data.value.sw012s04 !== null) {
        idUser = btoa(state.form_data.value.sw012s04)
      }
      params += `&sw012s04=${encodeURIComponent(idUser)}`

      return new Promise((resolve, reject) => {
        api.get(url + params)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar dados dos critérios')))
            }
          })
      })
    },

    async getCriteriosImovirtual({ state }) {
      const url = `${apiConfig.url_base_api}listings/getCriteriosImovirtual`

      let params = `?sw012s01=${state.keyReg.value}`

      let idUser = state.idAgent.value
      if (typeof state.form_data.value.sw012s04 !== 'undefined' && state.form_data.value.sw012s04 !== null) {
        idUser = btoa(state.form_data.value.sw012s04)
      }
      params += `&sw012s04=${encodeURIComponent(idUser)}`

      return new Promise((resolve, reject) => {
        api.get(url + params)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar dados dos critérios')))
            }
          })
      })
    },

    async getCriteriosEgo({ state }) {
      const url = `${apiConfig.url_base_api}listings/getCriteriosEgo`

      let params = `?sw012s01=${encodeURIComponent(state.keyReg.value)}`

      let idUser = state.idAgent.value
      if (typeof state.form_data.value.sw012s04 !== 'undefined' && state.form_data.value.sw012s04 !== null) {
        idUser = btoa(state.form_data.value.sw012s04)
      }
      params += `&sw012s04=${encodeURIComponent(idUser)}`

      return new Promise((resolve, reject) => {
        api.get(url + params)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar dados dos critérios')))
            }
          })
      })
    },

    async getCriteriosIdealista({ state }) {
      const url = `${apiConfig.url_base_api}listings/getCriteriosIdealista`

      let params = `?sw012s01=${encodeURIComponent(state.keyReg.value)}`

      let idUser = state.idAgent.value
      if (typeof state.form_data.value.sw012s04 !== 'undefined' && state.form_data.value.sw012s04 !== null) {
        idUser = btoa(state.form_data.value.sw012s04)
      }
      params += `&sw012s04=${encodeURIComponent(idUser)}`

      return new Promise((resolve, reject) => {
        api.get(url + params)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar dados dos critérios')))
            }
          })
      })
    },

    async getCriteriosListGlobally({ state }) {
      const url = `${apiConfig.url_base_api}listings/getCriteriosListGlobally`

      let params = `?sw012s01=${encodeURIComponent(state.keyReg.value)}`

      let idUser = state.idAgent.value
      if (typeof state.form_data.value.sw012s04 !== 'undefined' && state.form_data.value.sw012s04 !== null) {
        idUser = btoa(state.form_data.value.sw012s04)
      }
      params += `&sw012s04=${encodeURIComponent(idUser)}`

      return new Promise((resolve, reject) => {
        api.get(url + params)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar dados dos critérios')))
            }
          })
      })
    },

    async getCriteriosInmovilla({ state }) {
      const url = `${apiConfig.url_base_api}listings/getCriteriosInmovilla`

      let params = `?sw012s01=${encodeURIComponent(state.keyReg.value)}`

      let idUser = state.idAgent.value
      if (typeof state.form_data.value.sw012s04 !== 'undefined' && state.form_data.value.sw012s04 !== null) {
        idUser = btoa(state.form_data.value.sw012s04)
      }
      params += `&sw012s04=${encodeURIComponent(idUser)}`

      return new Promise((resolve, reject) => {
        api.get(url + params)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar dados dos critérios')))
            }
          })
      })
    },

    // Alteração de consultor na angariação
    async modalChangeConsultor({ state, commit }) {
      const formData = new FormData()

      formData.append('idList', btoa(state.form_data.value.sw012s01))
      formData.append('idCons', state.form_data.value.sw012s04)
      formData.append('nome', (state.infoAgent.value.nomeUser || state.infoAgent.value.nome))

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/modalChangeConsultor`, formData)
          .then(response => {
            if (typeof response.data !== 'object' || response.data.title === '') {
              reject(new Error(i18n.t('Problema a obter dados')))
            } else {
              commit('setFilterChangeAgent', response.data.data)
              resolve(true)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a obter dados')))
            }
          })
      })
    },

    // Gravar alteração do consultor na angariação
    async updateChangeConsultor({ state }, payload) {
      const formData = new FormData()

      formData.append('regDel', btoa(state.form_data.value.sw012s01))
      formData.append('idOldConsultor', state.form_data.value.sw012s04)
      formData.append('consultor', payload.consultor)
      formData.append('nomeConsultor', payload.nomeConsultor)
      formData.append('transDocs', payload.transDocs)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/updateChangeConsultor`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema ao grava a alteração do consultor')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao grava a alteração do consultor')))
            }
          })
      })
    },

    async getPricesLinsting({ state }) {
      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}listings/viewListPriceListing/${btoa(state.form_data.value.sw012s01)}`)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema ao obter histórico')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter histórico')))
            }
          })
      })
    },

    // Serialize form sw012
    async formSerialize({ state }) {
      const formData = new FormData()

      Object.entries(state.form_data.value).forEach(row => {
        const [name, value] = row

        if (name === 'sw012s01' && state.modeFormAction.value !== 1) {
          formData.append(name, btoa(value))
        } else if (name === 'sw012s06') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.sw007s01 : null))
        } else if (name === 'sw012s07') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s08') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.sw005s01 : null))
        } else if (name === 'sw012s08_plus') {
          if (typeof value !== 'undefined' && value !== null && value !== '') {
            formData.append(name, value.sw005s01)
          }
        } else if (name === 'sw012s09') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.sw008s01 : null))
        } else if (name === 'sw012s10') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.sw009s01 : null))
        } else if (name === 'sw012s11') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.sw010s01 : null))
        } else if (name === 'sw012s13') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s19') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.sw006s01 : null))
        } else if (name === 'sw012s22' || name === 'sw012s23' || name === 'sw012s24' || name === 'sw012s310' || name === 'sw012s549' || name === 'sw012s550') {
          formData.append(name, (value !== null && value !== false ? value : ''))
        } else if (name === 'sw012s26') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s39') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s40') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s41') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s42') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s43') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s44') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s47') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s64') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.sw018s01 : null))
        } else if (name === 'sw012s68') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s126') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s133') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s137') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s143') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s305') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s312') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.sw087s01 : null))
        } else if (name === 'sw012s314') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s363') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s376') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s390' && Object.keys(state.especialistas.value).length > 0) {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s391') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s466') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s483') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s517') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.id : null))
        } else if (name === 'sw012s536') {
          formData.append(name, (typeof value !== 'undefined' && value !== null && value !== '' ? value.sw361s09 : null))
        } else if (name === 'sw012s542') {
          //
        } else if (name === 'sw012s543') {
          //
        } else if (name === 'extras' || name === 'attributes' || name === 'header_detail') {
          // Não insere no objecto
        } else if (name.includes('sw012_attr')) {
          if (['sw012_attr_obras-no-imovel', 'sw012_attr_orientacao-solar'].includes(name)) {
            if (name === 'sw012_attr_obras-no-imovel') {
              if ((value !== null) && (value !== 'undefined')) {
                formData.append(name, value.id)
              }
            } else if ((value !== null) && (value !== 'undefined') && (value.length > 0)) {
              value.forEach(item => {
                formData.append('sw012_attr_orientacao-solar[]', item.id)
              })
            }
          } else if (typeof value !== 'undefined' && value !== null && value !== '') {
            formData.append(name, value)
          }
        } else if (name === 'sw019s03') {
          if (typeof value !== 'undefined' && value !== null && value !== '') {
            value.forEach(item => {
              formData.append('sw019s03[]', item.sw004s01)
            })
          } else {
            formData.append('sw019s03[]', null)
          }
        } else {
          formData.append(name, value)
        }
      })

      if (typeof state.idBankAssoc.value !== 'undefined') {
        formData.append('idBankAssoc', state.idBankAssoc.value)
      } else {
        formData.append('idBankAssoc', '')
      }

      if (typeof state.idAgent.value !== 'undefined') {
        formData.append('idConsultorSel', state.idAgent.value)
      } else {
        formData.append('idConsultorSel', '')
      }

      if ((state.form_data.value?.sw012s542?.id !== undefined) && (Number(state.form_data.value.sw012s542.id) === 1)) {
        formData.append('sw012s542', 1)
        formData.append('sw012s543', state.form_data.value?.sw012s543?.id || '')
      }

      formData.append('keyReg', state.keyReg.value)
      formData.append('dynamicIDForm', state.dynamicIDForm.value)
      formData.append('dynamicIDVersion', state.dynamicIDVersion.value)
      formData.append('idLeadAssoc', state.idLeadAssoc.value)
      formData.append('aprovarRegHM', state.aprovaListing.value)
      formData.append('modeFormAction', state.modeFormAction.value)
      formData.append('formPreAngariacao', state.formPreAngariacao.value)
      formData.append('forceFillWebsiteConfig', state.forceWebsiteConfig.value)
      formData.append('showVersionPending', state.showVersionPending.value)
      formData.append('idVersionReg', state.idVersionReg.value)
      formData.append('idVersionRegViewer', state.idVersionRegViewer.value)
      formData.append('viewEstudoMercado', '')

      state.form_sw078_default.value.forEach(item => {
        if (item.sw078s05 !== '') {
          formData.append('sw078s05[]', item.sw078s05)
        }

        if (item.sw078s06 !== '') {
          formData.append('sw078s06[]', item.sw078s06)
        }

        if (item.sw078s07 !== '') {
          formData.append('sw078s07[]', item.sw078s07)
        }
      })

      state.form_sw078.value.forEach(item => {
        formData.append('sw078s05[]', item.sw078s05)
        formData.append('sw078s06[]', item.sw078s06)
        formData.append('sw078s07[]', item.sw078s07)
      })

      state.form_sw086_default.value.forEach(item => {
        if (item.sw086s05 !== '') {
          formData.append('sw086s05[]', item.sw086s05)
        }

        if (item.sw086s06 !== '') {
          formData.append('sw086s06[]', item.sw086s06)
        }

        if (item.sw086s07 !== '') {
          formData.append('sw086s07[]', item.sw086s07)
        }
      })

      state.form_sw086.value.forEach(item => {
        formData.append('sw086s05[]', item.sw086s05)
        formData.append('sw086s06[]', item.sw086s06)
        formData.append('sw086s07[]', item.sw086s07)
      })

      return formData
    },

    validateRent({
      state, commit, rootGetters, dispatch,
    }, payload) {
      let estadoAngSel = ''
      let estadoAngDraftSel = ''
      let vlSW012s06 = ''
      let vlSW012s07 = ''

      if (typeof state.form_data.value.sw012s64 !== 'undefined' && state.form_data.value.sw012s64 !== null) {
        estadoAngSel = Number(state.form_data.value.sw012s64.sw018s01)
      }

      if (typeof state.form_data.value.sw012s70 !== 'undefined' && state.form_data.value.sw012s70 !== null) {
        estadoAngDraftSel = state.form_data.value.sw012s70
      }

      if (typeof state.form_data.value.sw012s06 !== 'undefined' && state.form_data.value.sw012s06 !== null) {
        vlSW012s06 = Number(state.form_data.value.sw012s06.sw007s01)
      }

      if (typeof state.form_data.value.sw012s07 !== 'undefined' && state.form_data.value.sw012s07 !== null) {
        vlSW012s07 = Number(state.form_data.value.sw012s07.id)
      }

      commit('setUpdateRuleField', { field: 'sw012s376', value: false })
      commit('setUpdateRuleField', { field: 'sw012s377', value: false })

      if (rootGetters['auth/countryApp'].toUpperCase() === 'AR') {
        commit('setUpdateRuleField', { field: 'sw012s516', value: false })
      }

      if (vlSW012s06 === 3) {
        commit('setCurrentlyRent', true)
      } else {
        commit('setCurrentlyRent', false)
      }

      if (vlSW012s06 === 1 || vlSW012s06 === 3) {
        if ([19, 15, 18, 3, 155, 156, 157, 119].includes(vlSW012s07)) {
          if (payload.clearInputs !== 1) {
            commit('setUpdateFormDataField', { field: 'sw012s376', value: null })
            commit('setUpdateFormDataField', { field: 'sw012s377', value: null })
            commit('setUpdateFormDataField', { field: 'rentabilidadeBruta', value: null })

            if (rootGetters['auth/countryApp'].toUpperCase() === 'AR') {
              commit('setUpdateFormDataField', { field: 'sw012s516', value: null })
            }
          }
        } else {
          if (payload.clearInputs !== 1) {
            if (rootGetters['auth/countryApp'].toUpperCase() === 'AR') {
              commit('setUpdateFormDataField', { field: 'sw012s516', value: null })
            }
          }

          if (estadoAngSel === 9 || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
            // não faz nada
          } else {
            commit('setUpdateRuleField', { field: 'sw012s376', value: true })
          }

          commit('setCurrentlyRent', true)

          dispatch('validateActualyRent')
        }
      } else {
        if ((vlSW012s06 === 2 || vlSW012s06 === 4) && rootGetters['auth/countryApp'].toUpperCase() === 'AR') {
          commit('setUpdateRuleField', { field: 'sw012s516', value: true })
        }

        if (payload.clearInputs !== 1) {
          commit('setUpdateFormDataField', { field: 'sw012s376', value: null })
          commit('setUpdateFormDataField', { field: 'sw012s377', value: null })
          commit('setUpdateFormDataField', { field: 'rentabilidadeBruta', value: null })

          if (rootGetters['auth/countryApp'].toUpperCase() === 'AR') {
            commit('setUpdateFormDataField', { field: 'sw012s516', value: null })
          }
        }
      }

      if (rootGetters['auth/countryApp'].toUpperCase() === 'PT') {
        if (typeof state.form_data.value.sw012s379 !== 'undefined' && state.form_data.value.sw012s379 !== null && Number(state.form_data.value.sw012s379) === 1) {
          // Para angariacoes externas nao existe esta funcionalidade
        } else if (vlSW012s06 === 2) {
          if (payload.clearInputs !== 1) {
            commit('setUpdateFormDataField', { field: 'sw012s230', value: null })
            commit('setUpdateFormDataField', { field: 'sw012s231', value: null })
            commit('setUpdateFormDataField', { field: 'sw012s241', value: null })
            commit('setUpdateFormDataField', { field: 'sw012s242', value: null })
          }

          commit('setUpdateRuleField', { field: 'sw012s230', value: true })
          commit('setUpdateRuleField', { field: 'sw012s231', value: false })
          commit('setUpdateRuleField', { field: 'sw012s241', value: false })
        } else {
          commit('setUpdateFormDataField', { field: 'sw012s230', value: null })
          commit('setUpdateFormDataField', { field: 'sw012s231', value: null })
          commit('setUpdateFormDataField', { field: 'sw012s241', value: null })
          commit('setUpdateFormDataField', { field: 'sw012s242', value: null })

          commit('setUpdateRuleField', { field: 'sw012s230', value: false })
          commit('setUpdateRuleField', { field: 'sw012s231', value: false })
          commit('setUpdateRuleField', { field: 'sw012s241', value: false })
        }
      }

      commit('setUpdateFormData', state.form_data.value)
    },

    validateActualyRent({ state, commit, dispatch }) {
      let estadoAngSel = ''
      let estadoAngDraftSel = ''
      let vlSW012s06 = ''
      let vlSW012s376 = ''

      if (typeof state.form_data.value.sw012s64 !== 'undefined' && state.form_data.value.sw012s64 !== null) {
        estadoAngSel = state.form_data.value.sw012s64
      }

      if (typeof state.form_data.value.sw012s70 !== 'undefined' && state.form_data.value.sw012s70 !== null) {
        estadoAngDraftSel = state.form_data.value.sw012s70
      }

      if (typeof state.form_data.value.sw012s06 !== 'undefined' && state.form_data.value.sw012s06 !== null) {
        vlSW012s06 = Number(state.form_data.value.sw012s06.sw007s01)
      }

      if (typeof state.form_data.value.vlSW012s376 !== 'undefined' && state.form_data.value.vlSW012s376 !== null) {
        vlSW012s376 = Number(state.form_data.value.vlSW012s376.id)
      }

      if (Number(vlSW012s376) === 1) {
        if (estadoAngSel === 9 || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
          // Não faz nada
        } else if (vlSW012s06 !== 3) {
          commit('setUpdateRuleField', { field: 'sw012s377', value: false })
        }

        dispatch('calculateRentabilidadeBruta')
      } else {
        commit('setUpdateFormDataField', { field: 'rentabilidadeBruta', value: null })
        commit('setUpdateFormData', state.form_data.value)
      }
    },

    calculateRentabilidadeBruta({ state, commit }) {
      let vlSW012s377 = 0
      if (typeof state.form_data.value.sw012s377 !== 'undefined' && state.form_data.value.sw012s377 !== null) {
        vlSW012s377 = state.form_data.value.sw012s377
      }

      let vlSW012s14 = 0
      if (typeof state.form_data.value.sw012s14 !== 'undefined' && state.form_data.value.sw012s14 !== null) {
        vlSW012s14 = state.form_data.value.sw012s14
      }

      let iRBValorRendaMensal = String(vlSW012s377)

      iRBValorRendaMensal = iRBValorRendaMensal.replace(/\./g, '').replace(',', '.')
      let iRBValorVenda = String(vlSW012s14)

      iRBValorVenda = iRBValorVenda.replace(/\./g, '').replace(',', '.')

      const iRBCalculo = ((iRBValorRendaMensal * 12) * 100) / iRBValorVenda
      const iRBCalculoFixed = iRBCalculo.toFixed(2)

      commit('setUpdateFormDataField', { field: 'rentabilidadeBruta', value: null })

      if (iRBValorVenda !== '0' && iRBValorRendaMensal !== '0') {
        if (iRBCalculoFixed) {
          commit('setUpdateFormDataField', { field: 'rentabilidadeBruta', value: `${iRBCalculoFixed} %` })
        }
      }
      commit('setUpdateFormData', state.form_data.value)
    },

    validatePAA({ state, commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        if (rootGetters['auth/countryApp'].toUpperCase() === 'PT') {
          if (typeof state.form_data.value.sw012s379 !== 'undefined' && state.form_data.value.sw012s379 !== null && Number(state.form_data.value.sw012s379) === 1) {
            // Para angariacoes externas nao existe esta funcionalidade
          } else if (typeof state.form_data.value.sw012s06 !== 'undefined' && state.form_data.value.sw012s06 !== null && Number(state.form_data.value.sw012s06.sw007s01) === 2) {
            const vlSW012s06 = Number(state.form_data.value.sw012s06.sw007s01)
            let vlSW012s230 = ''

            if (typeof state.form_data.value.sw012s230 !== 'undefined' && state.form_data.value.sw012s230 !== null) {
              vlSW012s230 = Number(state.form_data.value.sw012s230.id)
            }

            if (vlSW012s06 === 2 && vlSW012s230 === 1) {
              let vlSW012s69 = 0
              let vlSW012s242 = 0

              if (typeof state.form_data.value.sw012s69 !== 'undefined' && state.form_data.value.sw012s69 !== null) {
                vlSW012s69 = state.form_data.value.sw012s69
              }

              if (typeof state.form_data.sw012s242 !== 'undefined' && state.form_data.value.sw012s242 !== null) {
                vlSW012s242 = state.form_data.value.sw012s242
              }

              if (vlSW012s69 && vlSW012s242) {
                vlSW012s69 = vlSW012s69.replace(/\./g, '').replace(',', '.')
                vlSW012s242 = vlSW012s242.replace(/\./g, '').replace(',', '.')

                if (vlSW012s69 > vlSW012s242) {
                  commit('setUpdateFormDataField', { field: 'sw012s69', value: null })
                  commit('setUpdateFormData', state.form_data.value)
                  reject(new Error(i18n.t('O valor da renda mensal não pode ser superior ao valor máximo de renda do PAA')))
                }
              }
            }
          }
        }
        resolve(true)
      })
    },

    validateSW230({ state, commit, rootGetters }, payload) {
      if (rootGetters['auth/countryApp'].toUpperCase() === 'PT') {
        if (typeof state.form_data.value.sw012s379 !== 'undefined' && state.form_data.value.sw012s379 !== null && Number(state.form_data.value.sw012s379) === 1) {
          // Para angariacoes externas nao existe esta funcionalidade
        } else if (typeof state.form_data.value.sw012s06 !== 'undefined' && state.form_data.value.sw012s06 !== null && Number(state.form_data.value.sw012s06.sw007s01) === 2) {
          let vlSW012s230 = ''
          if (typeof state.form_data.value.sw012s230 !== 'undefined' && state.form_data.value.sw012s230 !== null) {
            vlSW012s230 = Number(state.form_data.value.sw012s230.id)
          }

          if (payload.clearInputs !== 1) {
            commit('setUpdateFormDataField', { field: 'sw012s231', value: null })
            commit('setUpdateFormDataField', { field: 'sw012s241', value: null })
            commit('setUpdateFormDataField', { field: 'sw012s242', value: null })
          }

          if (vlSW012s230 === 1) {
            commit('setUpdateRuleField', { field: 'sw012s231', value: true })
            commit('setUpdateRuleField', { field: 'sw012s241', value: true })
          } else {
            commit('setUpdateRuleField', { field: 'sw012s231', value: false })
            commit('setUpdateRuleField', { field: 'sw012s241', value: false })
          }

          commit('setUpdateFormData', state.form_data.value)
        }
      }
    },

    // Validar o CMI
    async validateCMI({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        if (state.appConfig.value.bUseValidateCMI_Angariacoes === true && typeof state.form_data.value.sw012s06 !== 'undefined' && state.form_data.value.sw012s06 !== null && Number(state.form_data.value.sw012s06.sw007s01) === 1 && typeof payload.vlCMI !== 'undefined' && payload.vlCMI !== null) {
          let vlCmi = payload.vlCMI

          vlCmi = vlCmi.replace(/\./g, '').replace(',', '.')

          if (vlCmi === 0 || vlCmi < 101.00 || vlCmi > 10001.00) {
            commit('setUpdateFormDataField', { field: 'sw012s16', value: null })
            commit('setUpdateFormData', state.form_data.value)
            reject(new Error(i18n.t('O valor deve ser superior a 101€ e menor que 10.001€')))
          } else {
            resolve(true)
          }
        } else {
          resolve(true)
        }
      })
    },

    // Validar a per CMI
    async validatePercCMI({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        if (state.appConfig.value.bUseValidateCMI_Angariacoes === true && typeof state.form_data.value.sw012s06 !== 'undefined' && state.form_data.value.sw012s06 !== null && typeof payload.vlPerCMI !== 'undefined' && payload.vlPerCMI !== null) {
          let price = ''
          if (typeof state.form_data.value.sw012s14 !== 'undefined' && state.form_data.value.sw012s14 !== null) {
            price = state.form_data.value.sw012s14.replace(/\./g, '').replace(',', '.')
          }

          let vlComissao = payload.vlPerCMI
          if (vlComissao) {
            vlComissao = parseFloat(vlComissao).toFixed(2)
          }

          commit('setInvalidFields', [])

          if ((state.form_data.value.sw012s06?.sw007s01 !== undefined) && (Number(state.form_data.value.sw012s06.sw007s01) === 1)) {
            if (
              (state.form_data.value.sw012s542?.id !== undefined)
              && (state.form_data.value.sw012s543?.id !== undefined)
              && (Number(state.form_data.value.sw012s542.id) === 1)
              && (Number(state.form_data.value.sw012s543.id) !== 0)
            ) {
              if (vlComissao < 4 || vlComissao > 10.01) {
                if ((payload?.clearInput !== undefined) && (payload.clearInput === 1)) {
                  commit('setUpdateFormDataField', { field: 'sw012s15', value: null })
                } else {
                  commit('setInvalidFields', ['sw012s15'])
                }

                commit('setUpdateFormData', state.form_data.value)

                reject(new Error(i18n.t('Se o valor da angariação for maior ou igual a 20.000€ e o imóvel pertencer a um Empreendimento, a percentagem da comissão é entre 4% a 10.01%')))
              } else {
                resolve(true)
              }
            } else if (
              (state.form_data.value.sw012s542?.id !== undefined)
              && (Number(state.form_data.value.sw012s542.id) === 1)
            ) {
              if (vlComissao < 4 || vlComissao > 10.01) {
                if ((payload?.clearInput !== undefined) && (payload.clearInput === 1)) {
                  commit('setUpdateFormDataField', { field: 'sw012s15', value: null })
                } else {
                  commit('setInvalidFields', ['sw012s15'])
                }

                commit('setUpdateFormData', state.form_data.value)

                reject(new Error(i18n.t('Se o valor da angariação for maior ou igual a 20.000€ e o imóvel pertencer a um Empreendimento, a percentagem da comissão é entre 4% a 10.01%')))
              } else {
                resolve(true)
              }
            } else if (price > 0) {
              if (price < 20000.00) {
                commit('setUpdateFormDataField', { field: 'sw012s15', value: 20 })
                commit('setUpdateFormData', state.form_data.value)

                if (vlComissao !== 20.00) {
                  reject(new Error(i18n.t('A percentagem da comissão foi alterada para 20%, devido ao valor da angariação ser menor que 20.000€')))
                } else {
                  resolve(true)
                }
              } else if (price >= 750000.00) {
                if (vlComissao < 4 || vlComissao > 10.01) {
                  if ((payload?.clearInput !== undefined) && (payload.clearInput === 1)) {
                    commit('setUpdateFormDataField', { field: 'sw012s15', value: null })
                  } else {
                    commit('setInvalidFields', ['sw012s15'])
                  }

                  commit('setUpdateFormData', state.form_data.value)

                  reject(new Error(i18n.t('O valor da angariação se for maior ou igual 750.000€, a percentagem da comissão é entre 4% a 10.01%')))
                } else {
                  resolve(true)
                }
              } else if (vlComissao < 4.90 || vlComissao > 10.01) {
                if ((payload?.clearInput !== undefined) && (payload.clearInput === 1)) {
                  commit('setUpdateFormDataField', { field: 'sw012s15', value: null })
                } else {
                  commit('setInvalidFields', ['sw012s15'])
                }

                commit('setUpdateFormData', state.form_data.value)

                reject(new Error(i18n.t('O valor da angariação se for maior ou igual a 20.000€ e menor do que 750.000€, a percentagem da comissão é entre 4.90% e 10.01%')))
              } else {
                resolve(true)
              }
            } else {
              resolve(true)
            }
          } else {
            resolve(true)
          }
        } else {
          resolve(true)
        }
      })
    },

    async validateMinCPCV({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        let valorCPCV = ''
        let valorVenda = ''

        if (typeof payload.vlMinCMI !== 'undefined' && payload.vlMinCMI !== null) {
          valorCPCV = payload.vlMinCMI
        }

        if (typeof state.form_data.value.sw012s14 !== 'undefined' && state.form_data.value.sw012s14 !== null) {
          valorVenda = String(state.form_data.value.sw012s14)
        }

        valorVenda = valorVenda.replace(/\./g, '').replace(',', '.')
        valorCPCV = valorCPCV.replace(/\./g, '').replace(',', '.')

        if (parseFloat(valorCPCV) >= parseFloat(valorVenda) && parseFloat(valorCPCV) !== 0.00) {
          commit('setUpdateFormDataField', { field: 'sw012s110', value: null })
          commit('setUpdateFormData', state.form_data.value)

          reject(new Error(i18n.t('O valor mínimo do CPCV não pode ser superior ao preço de venda')))
        } else {
          resolve(true)
        }
      })
    },

    // Suspender a angariação
    async suspenderReg({ state }) {
      const formData = new FormData()

      formData.append('regDel', btoa(state.form_data.value.sw012s01))

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/suspenderReg`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema a suspender a angariação')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a suspender a angariação')))
            }
          })
      })
    },

    // Eliminar a angariação
    async deleteListing({ state }, payload) {
      const formData = new FormData()

      formData.append('regDel', btoa(state.form_data.value.sw012s01))

      if (typeof payload.config === 'object') {
        Object.keys(payload.config).forEach(index => {
          formData.append(index, payload.config[index])
        })
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/delete`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema a eliminar a angariação')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a eliminar a angariação')))
            }
          })
      })
    },

    // Mostra/Esconde os atributos por tipo de imóvel
    async setAtributosByTipoImovel({ commit }, payload) {
      if (payload !== null) {
        if (Number(payload.fields_atrib_essential) === 1) {
          commit('setEssenciasAttributes', true)
          commit('setAreasAttributes', true)
          commit('setExtrasAttributes', true)
          commit('setVistasAttributes', true)
          commit('setEnergiaAttributes', true)
          commit('setSegurancaAttributes', true)
          commit('setEspacosAttributes', true)
          commit('setExteriorAttributes', true)
        } else if (Number(payload.id) === 15 || Number(payload.id) === 119) {
          commit('setEssenciasAttributes', false)
          commit('setAreasAttributes', true)
          commit('setExtrasAttributes', false)
          commit('setVistasAttributes', true)
          commit('setEnergiaAttributes', false)
          commit('setSegurancaAttributes', true)
          commit('setEspacosAttributes', false)
          commit('setExteriorAttributes', false)
        } else if (Number(payload.id) === 3 || Number(payload.id) === 155 || Number(payload.id) === 156 || Number(payload.id) === 157) {
          commit('setEssenciasAttributes', false)
          commit('setAreasAttributes', true)
          commit('setExtrasAttributes', false)
          commit('setVistasAttributes', true)
          commit('setEnergiaAttributes', false)
          commit('setSegurancaAttributes', false)
          commit('setEspacosAttributes', false)
          commit('setExteriorAttributes', true)
        } else if (Number(payload.id) === 19) {
          commit('setEssenciasAttributes', false)
          commit('setAreasAttributes', true)
          commit('setExtrasAttributes', false)
          commit('setVistasAttributes', false)
          commit('setEnergiaAttributes', false)
          commit('setSegurancaAttributes', false)
          commit('setEspacosAttributes', false)
          commit('setExteriorAttributes', false)
        } else {
          commit('setEssenciasAttributes', true)
          commit('setAreasAttributes', true)
          commit('setExtrasAttributes', true)
          commit('setVistasAttributes', true)
          commit('setEnergiaAttributes', true)
          commit('setSegurancaAttributes', true)
          commit('setEspacosAttributes', true)
          commit('setExteriorAttributes', true)
        }

        commit('setShowAssoalhadas', true)
        commit('setShowSuite', true)
        commit('setShowRoom', true)
        commit('setShowLugaresGaragem', true)
        commit('setShowQuintal', true)
        commit('setShowCozinha', true)
        commit('setShowSala', true)
        commit('setShowPiscina', true)

        if (Number(payload.id) === 1) {
          commit('setShowSuite', true)
          commit('setShowRoom', true)
        } else if (Number(payload.id) === 30) {
          commit('setShowAssoalhadas', false)
          commit('setShowSuite', false)
          commit('setShowRoom', false)
          commit('setShowLugaresGaragem', false)
          commit('setShowQuintal', false)
          commit('setShowPiscina', false)
        } else if (Number(payload.id) === 6 || Number(payload.id) === 7) {
          commit('setShowAssoalhadas', false)
          commit('setShowSuite', false)
          commit('setShowRoom', false)
          commit('setShowCozinha', false)
          commit('setShowSala', false)
          commit('setShowPiscina', false)
        } else if (Number(payload.id) === 4 || Number(payload.id) === 5 || Number(payload.id) === 27 || Number(payload.id) === 91) {
          commit('setShowSuite', false)
          commit('setShowRoom', false)
          commit('setShowQuintal', false)
          commit('setShowPiscina', false)
        } else if (Number(payload.id) === 18) {
          commit('setShowAssoalhadas', false)
          commit('setShowSuite', false)
          commit('setShowRoom', false)
        }
      } else {
        commit('setEssenciasAttributes', true)
        commit('setAreasAttributes', true)
        commit('setExtrasAttributes', true)
        commit('setVistasAttributes', true)
        commit('setEnergiaAttributes', true)
        commit('setSegurancaAttributes', true)
        commit('setEspacosAttributes', true)
        commit('setExteriorAttributes', true)
        commit('setShowLocalizacao', true)

        commit('setShowAssoalhadas', true)
        commit('setShowSuite', true)
        commit('setShowRoom', true)
        commit('setShowLugaresGaragem', true)
        commit('setShowQuintal', true)
        commit('setShowCozinha', true)
        commit('setShowSala', true)
        commit('setShowPiscina', true)
      }
    },

    setLoaded({ commit }) {
      commit('setLoaded', true)
    },

    setParamsUrl({ commit }, payload) {
      commit('setParamsUrl', payload)
    },

    setTypeAction({ commit }, payload) {
      commit('setTypeAction', payload)
    },

    setModeViewDetail({ commit }, payload) {
      commit('setModeViewDetail', payload)
    },

    setModeDraft({ commit }, payload) {
      commit('setModeDraft', payload)
    },

    // Atualiza coordena no mapa
    setMapLatLong({ state, commit }, payload) {
      commit('setMapLatLong', payload)
      commit('setUpdateFormData', state.form_data.value)
    },

    updateFieldValue({ state, commit }, payload) {
      commit('setUpdateFormDataField', payload)
      commit('setUpdateFormData', state.form_data.value)
    },

    updateRuleFieldValue({ commit }, payload) {
      commit('setUpdateRuleField', payload)
    },

    // Adicionar nova conservatória
    addNewConservatoria({ state, commit }) {
      commit('addNewConservatoria')
      commit('setUpdateFormData', state.form_data.value)
    },

    // Remove conservatória
    removeConservatoria({ commit }, payload) {
      commit('removeConservatoria', payload)
    },

    // Adicionar nova caderneta
    addNewCaderneta({ state, commit }) {
      commit('addNewCaderneta')
      commit('setUpdateFormData', state.form_data.value)
    },

    // Remove caderneta
    removeCaderneta({ commit }, payload) {
      commit('removeCaderneta', payload)
    },

    setLoadTabMedia({ commit }, payload) {
      commit('setLoadTabMedia', payload)
    },

    setArraySuites({ commit }, payload) {
      commit('setArraySuites', payload)
    },

    setValueCE({ commit }, payload) {
      commit('setValueCE', payload)
    },

    setCurrentlyRent({ commit }, payload) {
      commit('setCurrentlyRent', payload)
    },

    async getMarketStudy(state, payload) {
      return new Promise((resolve, reject) => {
        let urlRequestData = 'estudoMercado/analise'
        const { id } = payload

        if ((id !== undefined) && (id !== '')) {
          urlRequestData = `estudoMercado/analise/${id}`
        }

        api.get(`${apiConfig.url_base_api}${urlRequestData}`)
          .then(response => {
            try {
              if (checkNested(response, 'data.data.casafariUrl')) {
                resolve(response.data.data.casafariUrl)
              } else {
                reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
            }
          })
      })
    },

    validateLicenseNumber({ state, commit }) {
      let tipoNegocioSel = ''
      let tipoImovelSel = ''
      let estadoAngSel = ''
      let estadoAngDraftSel = ''
      let licencaIsento = ''

      if (typeof state.form_data.value.sw012s06 !== 'undefined' && state.form_data.value.sw012s06 !== null) {
        tipoNegocioSel = Number(state.form_data.value.sw012s06.sw007s01)
      }

      if (typeof state.form_data.value.sw012s07 !== 'undefined' && state.form_data.value.sw012s07 !== null) {
        tipoImovelSel = Number(state.form_data.value.sw012s07.id)
      }

      if (typeof state.form_data.value.sw012s64 !== 'undefined' && state.form_data.value.sw012s64 !== null) {
        estadoAngSel = Number(state.form_data.value.sw012s64.sw018s01)
      }

      if (typeof state.form_data.value.sw012s70 !== 'undefined' && state.form_data.value.sw012s70 !== null) {
        estadoAngDraftSel = state.form_data.value.sw012s70
      }

      if (typeof state.form_data.value.sw012s366 !== 'undefined' && state.form_data.value.sw012s366 !== null) {
        licencaIsento = Number(state.form_data.value.sw012s366)
      }
      if (estadoAngSel !== 9 && (estadoAngSel !== 5 || estadoAngDraftSel !== 1)) {
        if (tipoNegocioSel === 2 && (tipoImovelSel === 1 || tipoImovelSel === 2) && licencaIsento !== 1) {
          commit('setUpdateRuleField', { field: 'sw012s56', value: true })
          commit('setUpdateRuleField', { field: 'sw012s57', value: true })
        } else {
          commit('setUpdateRuleField', { field: 'sw012s56', value: false })
          commit('setUpdateRuleField', { field: 'sw012s57', value: false })
        }
      }
    },

    async validateRegimeContratacao({ state, commit }) {
      return new Promise(resolve => {
        let skipDisabledSelect = false
        let priceListing = 0
        let tipoNegocio = 0

        if (state.form_data.value?.sw012s06?.sw007s01 !== undefined) {
          tipoNegocio = Number(state.form_data.value.sw012s06.sw007s01)
        }

        if (tipoNegocio === 2) {
          if (typeof state.form_data.value.sw012s69 !== 'undefined' && state.form_data.value.sw012s69 !== null) {
            priceListing = state.form_data.value.sw012s69.replace(/\./g, '').replace(',', '.')
          }
        } else if (typeof state.form_data.value.sw012s14 !== 'undefined' && state.form_data.value.sw012s14 !== null) {
          priceListing = state.form_data.value.sw012s14.replace(/\./g, '').replace(',', '.')
        }

        if (priceListing > 0) {
          if ((priceListing >= 750000.00) && ((tipoNegocio === 1) || (tipoNegocio === 3))) {
            commit('setDisableRegimeContratacao', false)
            skipDisabledSelect = true
          }
        }

        if (
          (state.form_data.value.sw012s542?.id !== undefined)
          && (state.form_data.value.sw012s543?.id !== undefined)
          && (Number(state.form_data.value.sw012s542.id) === 1)
          && (Number(state.form_data.value.sw012s543.id) !== 0)
        ) {
          commit('setDisableRegimeContratacao', false)
          skipDisabledSelect = true
        }

        if (skipDisabledSelect === false) {
          commit('setUpdateFormDataField', { field: 'sw012s466', value: state.typeRegime.value.find(o => Number(o.id) === 1) })
          commit('setDisableRegimeContratacao', true)
        }

        resolve(true)
      })
    },

    async validateVentureExclusive({ state, commit, dispatch }) {
      dispatch('rulesValidateVentureExclusive')

      if (
        (state.form_data.value.sw012s07?.id !== undefined)
        && (state.idsTypeListingVenture.value !== undefined)
        && (state.idsTypeListingVenture.value.length > 0)
      ) {
        if (state.idsTypeListingVenture.value.includes(Number(state.form_data.value.sw012s07.id))) {
          //
        } else {
          commit('setUpdateFormDataField', { field: 'sw012s542', value: state.arrayYesNo.value.find(o => Number(o.id) === 0) })
          commit('setUpdateFormDataField', { field: 'sw012s543', value: null })
        }
      }

      // ricardox
      dispatch('validateRegimeContratacao')
      await dispatch('validatePercCMI', { vlPerCMI: state.form_data.value.sw012s15 || 0 })
    },

    rulesValidateVentureExclusive({ state, commit }, payload) {
      commit('setUpdateRuleField', { field: 'sw012s542', value: false })
      commit('setUpdateRuleField', { field: 'sw012s543', value: false })

      if ((payload?.status !== undefined) && (payload.status !== 'undefined') && ([5, 9].includes(payload.status))) {
        // Neste estados não é aplicado as regras de campos obrigatorios
      } else if (
        (state.form_data.value.sw012s07?.id !== undefined)
        && (state.idsTypeListingVenture.value !== undefined)
        && (state.idsTypeListingVenture.value.length > 0)
      ) {
        if (state.idsTypeListingVenture.value.includes(Number(state.form_data.value.sw012s07.id))) {
          commit('setUpdateRuleField', { field: 'sw012s542', value: true })

          if ((state.form_data.value?.sw012s542?.id !== undefined) && (Number(state.form_data.value.sw012s542.id) === 1)) {
            commit('setUpdateRuleField', { field: 'sw012s543', value: true })
          }
        }
      }
    },

    checkListingsAssocVenture({ state, dispatch }) {
      return new Promise(resolve => {
        let idVentureSel = ''
        if ((state.form_data.value.sw012s542?.id !== undefined) && (state.form_data.value.sw012s543?.id === 1) && (state.form_data.value.sw012s543?.id !== undefined) && (state.form_data.value.sw012s543?.id !== '')) {
          idVentureSel = state.form_data.value.sw012s543.id
        }

        const formData = new FormData()
        formData.append('keyReg', state.keyReg.value)
        formData.append('keyDynamic', state.dynamicIDForm.value)
        formData.append('modeForm', state.modeFormAction.value)
        formData.append('versionDynamic', state.dynamicIDVersion.value)
        formData.append('versionReg', state.idVersionReg.value)
        formData.append('idVentureSel', idVentureSel || '')
        formData.append('idVenturePrevious', state.form_data_sw012.value.sw012s543?.id || '')

        dispatch('request/cancelTokensPendingByUrl', 'listings/checkListingsAssocVenture', { root: true })

        api.post(`${apiConfig.url_base_api}listings/checkListingsAssocVenture`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              if ((response.data?.error !== undefined) && (response.data.error === 1)) {
                resolve(true)
              } else {
                resolve(false)
              }
            }
          })
      })
    },

    checkListingsAssocVentureSubmit({ state, dispatch }, payload) {
      return new Promise(resolve => {
        let idVentureSel = ''
        if ((state.form_data.value.sw012s542?.id !== undefined) && (state.form_data.value.sw012s543?.id === 1) && (state.form_data.value.sw012s543?.id !== undefined) && (state.form_data.value.sw012s543?.id !== '')) {
          idVentureSel = state.form_data.value.sw012s543.id
        }

        const formData = new FormData()
        formData.append('keyReg', state.keyReg.value)
        formData.append('keyDynamic', state.dynamicIDForm.value)
        formData.append('modeForm', state.modeFormAction.value)
        formData.append('versionDynamic', state.dynamicIDVersion.value)
        formData.append('versionReg', state.idVersionReg.value)
        formData.append('idVentureSel', idVentureSel || '')
        formData.append('idVenturePrevious', state.form_data_sw012.value?.sw012s543?.id || '')
        formData.append('nextStatusSubmit', payload.status || '')

        dispatch('request/cancelTokensPendingByUrl', 'listings/checkListingsAssocVenture', { root: true })

        api.post(`${apiConfig.url_base_api}listings/checkListingsAssocVenture`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              if ((response.data?.error !== undefined) && (response.data.error === 1) && (response.data.msg !== '')) {
                resolve(response.data.msg)
              } else if ((response.data?.error !== undefined) && (response.data.msg_error_submit !== '')) {
                resolve(response.data.msg_error_submit)
              } else {
                resolve('')
              }
            }
          })
      })
    },

    async getVenturesByAgent({ commit }, payload) {
      const formData = new FormData()

      formData.append('sw012s04', payload.sw012s04)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/getVenturesByAgent`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema obter os empreendimentos')))
            } else {
              commit('setEnterprises', response.data.ventures)
              resolve(true)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema obter os empreendimentos')))
            }
          })
      })
    },

    async getCriteriosCasayes({ state }) {
      const url = `${apiConfig.url_base_api}listings/getCriteriosCasayes`

      let params = `?sw012s01=${encodeURIComponent(state.keyReg.value)}`

      let idUser = state.idAgent.value
      if (typeof state.form_data.value.sw012s04 !== 'undefined' && state.form_data.value.sw012s04 !== null) {
        idUser = btoa(state.form_data.value.sw012s04)
      }
      params += `&sw012s04=${encodeURIComponent(idUser)}`

      return new Promise((resolve, reject) => {
        api.get(url + params)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar dados dos critérios')))
            }
          })
      })
    },
  },
}
