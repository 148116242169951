import { ref } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import i18n from '@/libs/i18n'

const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

export const defaultState = () => ({
  // Gerais
  mqShallShowLeftSidebar,
  tabContentStepShow: ref(0),
  loaded: ref(false),
  paramsUrl: ref({}),
  typeAction: ref(''),
  appConfig: ref({}),

  // Registo principal - sw012
  form_data: ref({}),
  form_data_sw012: {},

  // Conservatória
  form_sw086_default: ref([
    {
      sw086s05: '',
      sw086s06: '',
      sw086s07: '',
    },
  ]),

  form_sw086: ref([]),

  // Caderneta
  form_sw078_default: ref([
    {
      sw078s05: '',
      sw078s06: '',
      sw078s07: '',
    },
  ]),

  form_sw078: ref([]),

  // load separadores
  loadTabMedia: ref(false),
  loadTabPrivado: ref(false),
  loadTabAtividade: ref(false),
  loadTabPlano: ref(false),

  // atributos
  essenciais: ref(true),
  areas: ref(true),
  extras: ref(true),
  vistas: ref(true),
  energia: ref(true),
  seguranca: ref(true),
  espacos: ref(true),
  exterior: ref(true),
  localizacao: ref(true),
  showExtras: ref(false),
  showVistas: ref(false),
  showEnergia: ref(false),
  showSeguranca: ref(false),
  showEspacos: ref(false),
  showExterior: ref(false),
  showLocalizacao: ref(false),

  // atributos dinâmicos
  showAttrAssoalhadas: ref(true),
  showAttrSuite: ref(true),
  showAttrRoom: ref(true),
  showAttrLugaresGaragem: ref(true),
  showAttrQuintal: ref(true),
  showAttrCozinha: ref(true),
  showAttrSala: ref(true),
  showAttrPiscina: ref(true),

  // campos dinâmicos
  currentlyRent: ref(false),

  // Dados base formulario
  infoAgent: ref([]),
  infoLead: ref({}),
  infoBank: ref({}),
  infoMarket: ref([]),
  infoEmpreendimento: ref([]),
  usersAgent: ref([]),
  languages: ref([]),
  banks: ref([]),
  energy: ref([]),
  typologiesClasses: ref([]),
  conditions: ref([]),
  districts: ref([]),
  enterprises: ref([]),
  listingStates: ref([]),
  finality: ref([]),
  floors: ref([]),
  solarOrientations: ref([]),
  descriptonScripts: ref([]),
  typeBusiness: ref([]),
  typeLands: ref([]),
  typologies: ref([]),
  typeCategory: ref([]),
  places: ref([]),
  attributes: ref([]),
  previewPhotos: ref([]),
  previewImage: ref(''),
  typeListings: ref([]),
  subTypeListings: ref([]),
  typologiesExtra: ref([]),
  descriptonTrads: ref([]),
  rentPeriod: ref([]),
  blockBankOption: ref(''),
  historyPricesListing: ref([]),
  especialistas: ref([]),
  leader: ref([]),
  limitContratoMediacao: ref(false),
  planoComunicacao: ref([]),
  subTiposImoveis: ref([]),
  subTiposImoveisSel: ref([]),
  certificadoBy: ref(''),
  typeRegime: ref([
    {
      id: '1',
      desc: i18n.t('Exclusividade'),
    },
    {
      id: '2',
      desc: `${i18n.t('Não exclusividade')} (${i18n.t('exclusivo de rede')})}`,
    },
  ]),
  garages: ref([
    {
      id: '1',
      desc: i18n.t('Não'),
    },
    {
      id: '2',
      desc: i18n.t('Aberta'),
    },
    {
      id: '3',
      desc: i18n.t('Fechada'),
    },
  ]),
  arrayYesNo: ref([
    {
      id: '0',
      desc: i18n.t('Não'),
    },
    {
      id: '1',
      desc: i18n.t('Sim'),
    },
  ]),
  arrayCondominioYesNo: ref([
    {
      id: '2',
      desc: i18n.t('Não'),
    },
    {
      id: '1',
      desc: i18n.t('Sim'),
    },
  ]),
  array20: ref([
    {
      id: '0',
      desc: i18n.t('Não'),
    },
  ]),
  array70: ref([
    {
      id: '0',
      desc: i18n.t('Não'),
    },
  ]),
  arrayMeses: ref([
    {
      id: '6',
      desc: '6',
    },
    {
      id: '9',
      desc: '9',
    },
    {
      id: '12',
      desc: '12',
    },
    {
      id: '18',
      desc: '18',
    },
  ]),
  arrayMesesOutro: ref([
    {
      id: '3',
      desc: '3',
    },
    {
      id: '6',
      desc: '6',
    },
    {
      id: '9',
      desc: '9',
    },
    {
      id: '12',
      desc: '12',
    },
    {
      id: '18',
      desc: '18',
    },
    {
      id: '0',
      desc: i18n.t('Outro'),
    },
  ]),
  arrayPermuta: ref([
    {
      id: '1',
      desc: i18n.t('Sim'),
    },
    {
      id: '2',
      desc: i18n.t('Não'),
    },
    {
      id: '3',
      desc: i18n.t('Indiferente'),
    },
  ]),
  arrayCondominio: ref([
    {
      id: '1',
      desc: i18n.t('Mensal'),
    },
    {
      id: '2',
      desc: i18n.t('Anual'),
    },
    {
      id: '3',
      desc: i18n.t('Semestral'),
    },
    {
      id: '4',
      desc: i18n.t('Trimestral'),
    },
  ]),
  arraySuites: ref([
    {
      id: '0',
      desc: i18n.t('Não'),
    },
  ]),
  typeGarage: ref([
    {
      id: '1',
      desc: i18n.t('Fija'),
    },
    {
      id: '2',
      desc: i18n.t('Movil'),
    },
  ]),
  arrayConstruction: ref([
    {
      id: '1',
      desc: i18n.t('Muitas'),
    },
    {
      id: '2',
      desc: i18n.t('Poucas'),
    },
    {
      id: '3',
      desc: i18n.t('Nenhumas'),
    },
  ]),

  // Variaveis dinamicas
  keyReg: ref(''),
  idVersionReg: ref(''),
  dynamicIDVersion: ref(''),
  dynamicIDForm: ref(''),
  luxury: ref(''),
  crypto: ref(''),
  idAgent: ref(''),
  idAgentLead: ref(''),
  lead: ref(''),
  idLeadAssoc: ref(''),
  idBankAssoc: ref(''),
  empreAssoc: ref(''),
  idUserSearch: ref(''),
  oldCE: ref(''),
  formPreAngariacao: ref(''),
  currentState: ref(''),
  forceWebsiteConfig: ref(''),
  showVersionPending: ref(''),
  idVersionRegViewer: ref(''),
  diasVendido: ref(0),
  urlWebsite: ref(''),
  urlWebsiteLuxury: ref(''),
  urlWebsiteImovirtual: ref(''),
  urlWebsiteListglobally: ref(''),
  urlWebsiteCryptos: ref(''),
  urlWebsiteIdealista: ref(''),
  urlWebsiteCasayes: ref(''),
  stateImovirtual: ref(0),
  imovirtualErrorMessage: '',
  imovirtualModerateMessage: '',
  idealistaError: '',
  casayesError: '',
  bLuxurySelectable: ref(0),
  bCryptoSelectable: ref(0),
  portals: ref({}),
  showVersionReg: ref(0),
  aprovaListing: ref(0),
  temEstudoMercado: ref(0),
  userChangeCanEditAng: ref(0),
  editListingHM: ref(0),
  linkInterno: ref(''),
  idsTypeListingVenture: ref([]),
  checkStatusApproveVersionReg: ref(0),
  totalImages: ref(0),

  // Campos obrigatórios
  rulesFields: ref({
    sw012s03: { required: true },
    sw012s06: { required: true },
    sw012s07: { required: true },
    sw012s11: { required: true },
    sw012s15: { required: true },
    sw012s16: { required: true },
    sw012s17: { required: true },
    sw012s27: { required: true },
    sw012s28: { required: true },
    sw012s29: { required: true },
    sw012s30: { required: true },
    sw012s34: { required: true },
    sw012s35: { required: true },
    sw012s48: { required: true },
    sw012s50: { required: true },
    sw012s64: { required: true },
    sw012s67: { required: true },
    sw012s68: { required: true },
    sw012s299: { required: true },
    sw012s312: { required: true },
    sw012s391: { required: true },
    sw012s466: { required: true },
    sw012s536: { required: true },
    sw012s542: { required: false },
    sw012s543: { required: false },
  }),
  rulesFieldsAngariacao: ref({
    sw012s03: { required: true },
    sw012s06: { required: true },
    sw012s07: { required: true },
    sw012s11: { required: true },
    sw012s15: { required: true },
    sw012s16: { required: true },
    sw012s17: { required: true },
    sw012s27: { required: true },
    sw012s28: { required: true },
    sw012s29: { required: true },
    sw012s30: { required: true },
    sw012s34: { required: true },
    sw012s35: { required: true },
    sw012s48: { required: true },
    sw012s50: { required: true },
    sw012s64: { required: true },
    sw012s67: { required: true },
    sw012s68: { required: true },
    sw012s299: { required: true },
    sw012s312: { required: true },
    sw012s391: { required: true },
    sw012s466: { required: true },
    sw012s536: { required: true },
  }),
  rulesFieldsPreAngariacao: ref({
    sw012s03: { required: true },
  }),
  rulesAttrFields: ref({
    sw012s13: { required: true },
    sw012s37: { required: true },
    sw012s39: { required: true },
    sw012s41: { required: true },
    sw012s42: { required: true },
    sw012s43: { required: true },
    sw012s44: { required: true },
    sw012s47: { required: true },
    sw012s126: { required: true },
    sw012s137: { required: true },
    sw012s133: { required: true },
    sw012s143: { required: true },
  }),
  rulesAttrFieldsAngariacao: ref({
    sw012s13: { required: true },
    sw012s37: { required: true },
    sw012s39: { required: true },
    sw012s41: { required: true },
    sw012s42: { required: true },
    sw012s43: { required: true },
    sw012s44: { required: true },
    sw012s47: { required: true },
    sw012s126: { required: true },
    sw012s137: { required: true },
    sw012s133: { required: true },
    sw012s143: { required: true },
  }),
  rulesAttrFieldsPreAngariacao: ref({}),

  // Alteração do consultor
  filterChangeAgent: ref({}),

  disableRegimeContratacao: ref(true),

  invalidFields: ref([]),

})

export const defaultStateRouter = () => ({
  modeFormAction: ref(''),
  modeFormDraft: ref(''),
})
