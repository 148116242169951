<template>
  <div>

    <div
      class="email-app-list position-relative height-100-panel"
    >
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-items-center flex-grow-1 w-50">

          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              :size="sizeIconsAction"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>

        </div>
        <div class="d-flex align-items-center w-50 justify-content-end">

          <b-button
            v-if="this.$store.getters['listings_form/formModeView'] === false"
            variant="flat-secondary"
            class="border-radius-none"
            :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true : false)}"
            @click="$refs.refImagesListing.$el.click()"
          >
            <feather-icon
              icon="PlusIcon"
              :size="sizeIconsAction"
            />
          </b-button>

          <footer-step-nav ref="footerStepNav" />

        </div>
      </div>

      <vue-perfect-scrollbar
        class="scroll-content"
        :settings="configSettingsScroll"
      >
        <div
          class="app-fixed-search d-flex align-items-center"
          :class="{'border-0' : ((images.length > 0) ? false : true)}"
        >
          <div class="d-flex flex-column align-items-center">

            <div class="d-flex align-items-center justify-content-start w-100">
              <h4 class="p-1 m-0">
                {{ $t('Fotografias') }}
              </h4>
            </div>
            <div
              v-if="this.$store.getters['listings_form/formModeView'] === false"
              class="d-flex align-items-center justify-content-start w-100 pl-1 pr-1 pb-1"
            >
              <b-dropdown
                v-if="images.length !== 0"
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                split
                :text="$t('Carregar fotografias')"
                variant="outline-primary"
                @click="$refs.refImagesListing.$el.click()"
              >
                <b-dropdown-item v-if="typeof form_data.sw012s01 !== 'undefined' && form_data.sw012s01 !== null && form_data.sw012s01 !== ''">
                  {{ $t("Descarregar com marca d'agua") }}
                </b-dropdown-item>
                <b-dropdown-item v-if="typeof form_data.sw012s01 !== 'undefined' && form_data.sw012s01 !== null && form_data.sw012s01 !== ''">
                  {{ $t("Descarregar sem marca d'agua") }}
                </b-dropdown-item>
                <b-dropdown-item @click="setShowPhotosEdition(true)">
                  {{ $t('Ordenar / Eliminar imagens') }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                v-else
                variant="outline-primary"
                @click="$refs.refImagesListing.$el.click()"
              >
                <span class="align-middle">{{ $t('Carregar fotografias') }}</span>
              </b-button>
              <b-form-file
                ref="refImagesListing"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                multiple
                @input="uploadImageRender"
              />
            </div>

          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <b-row v-if="showInfoImagens === true">
            <b-col md="12">
              <b-alert
                show
                variant="info"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span class="ml-25">{{ $t("Por regra, 15 dias após uma angariação passar para o estado ‘Arrendado’, ‘Cancelado’ ou ‘Lixo’, as suas imagens originais são eliminadas definitivamente, mantendo-se apenas a ‘Imagem principal’. No caso da angariação estar no estado ‘Vendido’ o prazo é de 45 dias.") }}</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row v-if="formModeView === false">
            <b-col md="12">
              <b-alert
                show
                variant="info"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span class="ml-25"><strong>{{ $t("Não carregue fotografias com marca d'água") }}!</strong> {{ $t('Limite de tamanho:') }} <strong>{{ $t('10MB') }}</strong> {{ $t('por ficheiro') }}</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row
            v-if="images.length === 0"
          >
            <b-col md="12">
              <b-alert
                show
                variant="danger"
              >
                <div class="alert-body">
                  <span class="ml-25">{{ $t('Nenhuma foto inserida') }}</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col
              v-for="(row, key) in images"
              :key="`image-${key}`"
              md="4"
            >
              <component
                :is="blockCardImage"
                :image="row"
              >
                <template slot="blockActions">
                  <div class="grid-view m-0 p-1">
                    <b-row
                      v-if="formModeView === false"
                      :style="[{ 'min-height': '34px' }]"
                    >
                      <b-col md="12">
                        <b-form-checkbox
                          :checked="mainImages.length > 0 && mainImages.includes(row.idFixed) ? true : false"
                          switch
                          inline
                          @change="updateMainImage($event, key)"
                        >
                          {{ $t('Principal') }}
                        </b-form-checkbox>
                        <feather-icon
                          v-if="mainImages.length > 0 && mainImages.includes(row.idFixed)"
                          v-b-tooltip.hover.top="(typeof imagesPrin !== 'undefined' && typeof imagesPrin.datasByID !== 'undefined' && (row.idFixed in imagesPrin.datasByID) ? imagesPrin.datasByID[row.idFixed] : '')"
                          icon="StarIcon"
                          class="text-warning"
                          width="20px"
                          height="20px"
                        />
                      </b-col>
                    </b-row>
                    <div
                      class="item-options text-center"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="$t('Tag imagem') | lowerBold"
                      >
                        <b-form-group
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <v-select
                            v-if="formModeView === false"
                            :id="`tagImage_${row.id}`"
                            v-model="row.id_tag"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="row.tags_image"
                            append-to-body
                            :calculate-position="withPopper"
                            placeholder=""
                            label="sw286s02"
                            item-text="sw286s02"
                            item-value="sw286s01"
                            @input="updateDescImage(row.id, $event)"
                          >
                            <template #option="{ sw286s02 }">
                              {{ sw286s02 }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                          </v-select>
                          <p
                            v-else
                            class="text-left text-primary mb-0"
                          >
                            {{ renderObjToTxt(row.id_tag, 'sw286s02') }}
                          </p>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                </template>
              </component>
            </b-col>
          </b-row>

        </b-container>
      </vue-perfect-scrollbar>

      <!-- Photos Order/Delete -->
      <photos-edition
        :class="{'show': showPhotosEdition}"
        :main-images="mainImages"
        :delete-action="deleteImages"
        :order-action="changeOrderImages"
        @close-photos-edition="setShowPhotosEdition(false)"
      />

      <!-- modal -->
      <b-modal
        id="modal-xs"
        centered
        size="xl"
        :hide-footer="true"
      >
        <swiper
          class="swiper"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :style="{'--swiper-navigation-color': '#000','--swiper-pagination-color': '#fff'}"
        >
          <swiper-slide
            v-for="data in images"
            :key="data.src_image_preview"
          >
            <div class="swiper-zoom-container">
              <b-img
                :src="data.src_image_preview + '?v=' + new Date().getTime()"
                class="w-75 image-swiper"
                fluid
              />
            </div>
          </swiper-slide>
          <!-- Add Arrows -->
          <div
            slot="button-next"
            class="swiper-button-next"
          />
          <div
            slot="button-prev"
            class="swiper-button-prev"
          />
          <div
            slot="pagination"
            class="swiper-pagination swiper-pagination-white"
          />
        </swiper>
      </b-modal>

    </div>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BContainer, BAlert, BButton, BFormFile, BFormCheckbox, BModal, VBModal, BImg, BDropdown, BDropdownItem, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import modelSW014 from '@store-modules/listings/sw014'
import vSelect from 'vue-select'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { ref, onUnmounted } from '@vue/composition-api'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationProvider } from 'vee-validate'
import { mask } from 'vue-the-mask'
import { showModalDeleteMessage } from '@core/utils/utils'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'
import FooterStepNav from '../footerStepNavTop.vue'
import PhotosEdition from './sw014/list.vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BAlert,
    BButton,
    BFormFile,
    vSelect,
    BFormCheckbox,
    BModal,
    VBModal,
    BImg,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    Swiper,
    SwiperSlide,
    VuePerfectScrollbar,
    FooterStepNav,
    ValidationProvider,
    PhotosEdition,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
    mask,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
      maskDateYear: {
        date: true,
        dateMin: '1000',
        dateMax: '2099',
        datePattern: ['Y'],
      },
      maskDate: {
        date: true,
        delimiter: '-',
        datePattern: ['Y', 'm', 'd'],
      },
      dragging: false,
      swiperOptions: {
        zoom: true,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      resumeErrorUpload: [],
    }
  },
  computed:
  {
    ...mapGetters('sw014', ['images', 'imagesPrin', 'mainImages', 'showPhotosEdition', 'filterSelected']),
    ...mapGetters('listings_form', ['formModeView', 'form_data', 'load_data_geral']),
    images: {
      get() {
        return this.$store.getters['sw014/images']
      },
      set(value) {
        this.$store.commit('sw014/setImagens', value)
      },
    },
    showInfoImagens() {
      return (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null && this.form_data.sw012s64 !== '' && [3, 4, 7].includes(Number(this.form_data.sw012s64.sw018s01)) && this.load_data_geral?.totalImages <= 1)
    },
  },
  methods: {
    async uploadImageRender(aFiles) {
      if (aFiles.length > 0) {
        try {
          this.$root.$emit('app::loading', true)
          await store.dispatch('sw014/uploadSW014', { files: aFiles }).then(result => {
            this.resetFieldUpload()

            Object.keys(result).forEach(index => {
              if (result[index].error === false) {
                this.resumeErrorUpload.push(result[index])
              }
            })

            this.loadImages()
          }).catch(error => {
            this.resetFieldUpload()
            this.showMsgErrorRequest(error)
          })
        } catch (err) {
          this.resetFieldUpload()
        }
      }
    },
    async loadImages() {
      await store.dispatch('sw014/loadImages').then(async response => {
        if (typeof response === 'object') {
          await this.$store.commit('sw014/setImagens', response.imagesAPI)
          await this.$store.commit('sw014/setImagensPrin', response.imagesPrin)
          if (typeof response.imagesAPI[0] !== 'undefined' && typeof response.imagesAPI[0].src_image_preview !== 'undefined') {
            await this.$store.commit('listings_form/setPreviewImage', response.imagesAPI[0].src_image_preview)
          }

          const aMainImages = []

          await response.imagesAPI.forEach(async row => {
            if (typeof response.imagesPrin.ids !== 'undefined' && response.imagesPrin.ids.includes(row.idFixed)) {
              aMainImages.push(row.idFixed)
            } else {
              aMainImages.push('')
            }
          })

          await this.$store.commit('sw014/setMainImages', aMainImages)
        } else {
          this.showMsgErrorRequest(this.$t('Problema a carregar as imagens'))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async changeOrderImages() {
      this.$root.$emit('app::loading', true)

      await store.dispatch('sw014/changeOrderImages', { images: this.images }).then(response => {
        if (typeof response === 'object' && response.msg === '') {
          if (typeof this.images[0].src_image_preview !== 'undefined') {
            this.$store.commit('listings_form/setPreviewImage', this.images[0].src_image_preview)
          }
          this.showMsgSuccessRequest({ message: this.$t('Ordenação alterada com sucesso') })
        } else {
          this.showMsgErrorRequest(this.$t('Problema a trocar a ordenação das imagens'))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$root.$emit('app::loading', false)
    },
    async deleteImages() {
      const self = this

      try {
        if (this.filterSelected.imagesID.value.length > 0) {
          const actionModal = new Promise(resolve => {
            showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar a(s) foto(s)?')}<br>${this.$t('Esta ação é irreversível!')}`)
          })

          await actionModal.then(result => {
            if (result === 'confirm') {
              self.$root.$emit('app::loading', true)

              store.dispatch('sw014/deleteImage', { regDel: this.filterSelected.imagesID.value }).then(response => {
                if (response.error === 1) {
                  self.$root.$emit('app::loading', false)
                  self.showMsgErrorRequest(self.$t('Problema a eliminar imagens'))
                } else {
                  self.loadImages()
                  self.showMsgSuccessRequest({ message: response.msg })
                  self.$root.$emit('app::loading', false)
                }
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            }
          })
        }
      } catch (err) {
        // console.log(err)
      }
    },
    updateDescImage(idImage, idTag) {
      if (idTag !== null) {
        this.$root.$emit('app::loading', true)
        store.dispatch('sw014/updateDescImage', { regImage: idImage, tagSel: idTag.sw286s01, versionDynamic: '' }).then(res => {
          if (res.error === 1) {
            this.showMsgErrorRequest(res.msg)
          } else {
            this.images.forEach((row, index) => {
              if (row.id === idImage) {
                this.images[index].id_tag = idTag
                this.$store.commit('sw014/setImagens', this.images)
              }
            })
          }
          this.$root.$emit('app::loading', false)
        }).catch(error => {
          this.showMsgErrorRequest(error)
          this.$root.$emit('app::loading', false)
        })
      }
    },
    async updateMainImage(event, index) {
      this.$root.$emit('app::loading', true)

      if (typeof this.mainImages[index] !== 'undefined' && this.mainImages.includes(this.images[index].idFixed) && event === false) {
        await this.$set(this.mainImages, index, '')
      } else if (event === true) {
        await this.$set(this.mainImages, index, this.images[index].idFixed)
      }

      let ids = ''
      let idsFixed = ''

      await this.mainImages.forEach(async item => {
        await this.images.forEach(async row => {
          if (row.idFixed === item) {
            ids += `${row.id};`
            idsFixed += `${btoa(row.idFixed)};`
          }
        })
      })

      if (ids !== '') {
        ids = ids.slice(0, -1)
      }

      if (idsFixed !== '') {
        idsFixed = idsFixed.slice(0, -1)
      }

      await store.dispatch('sw014/saveImagesPrincipais', { orderImages: ids, idImagesFixed: idsFixed }).then(res => {
        if (res.error === 1) {
          this.showMsgErrorRequest(new Error(res.msg))
        } else {
          this.showMsgSuccessRequest({ message: res.msg })
          this.loadImages()
        }
        this.$root.$emit('app::loading', false)
      }).catch(error => {
        this.showMsgErrorRequest(error)
        this.$root.$emit('app::loading', false)
      })
    },
    setShowPhotosEdition(value) {
      this.$store.commit('sw014/setShowPhotosEdition', value)
    },
    resetFieldUpload() {
      this.$root.$emit('app::loading', false)
      this.resumeErrorUpload = []
      this.$refs.refImagesListing.reset()
    },
  },
  setup() {
    const refImagesListing = ref(null)
    const MODEL_SW014 = 'sw014'
    const blockCardImage = () => import('@/views/modules/listings/form/form_data/media/sw014/cardImage.vue')

    if (!store.hasModule(MODEL_SW014)) {
      store.registerModule(MODEL_SW014, modelSW014)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW014)) store.unregisterModule(MODEL_SW014)
      })
    }

    return {
      refImagesListing,
      blockCardImage,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>

.email-app-list .scroll-area {
  height: calc( 100% - calc(1.3rem) )!important;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper .swiper-slide {
  background: rgb(255, 255, 255);
}

</style>
